import { SVGProps } from 'react'
import {
  CategoryFinanceIcon,
  CategorySkillupIcon,
  CategoryProductivityIcon,
  CategoryLeadershipIcon,
  CategoryManagementIcon,
  CategoryInnovationIcon,
  CategoryHrIcon,
  CategoryMarketingIcon,
  CategoryIndustryIcon,
  CategoryGlobalIcon,
  CategoryEconomyIcon,
  CategoryItIcon,
  CategoryScienceIcon,
  CategoryLiberalartsIcon,
  CategoryHealthIcon,
  CategoryTrendIcon,
  CategoryMindIcon,
} from '@/components/icon'

interface Props {
  categoryId: number
  svgProps?: SVGProps<SVGSVGElement>
}

export const CategoryIcon: React.FC<Props> = ({ categoryId, svgProps }) => {
  const categoryIcons = [
    { categoryId: 1, icon: <CategorySkillupIcon {...svgProps} /> },
    { categoryId: 2, icon: <CategoryMindIcon {...svgProps} /> },
    { categoryId: 3, icon: <CategoryProductivityIcon {...svgProps} /> },
    { categoryId: 4, icon: <CategoryLeadershipIcon {...svgProps} /> },
    { categoryId: 5, icon: <CategoryManagementIcon {...svgProps} /> },
    { categoryId: 6, icon: <CategoryInnovationIcon {...svgProps} /> },
    { categoryId: 7, icon: <CategoryHrIcon {...svgProps} /> },
    { categoryId: 8, icon: <CategoryMarketingIcon {...svgProps} /> },
    { categoryId: 9, icon: <CategoryIndustryIcon {...svgProps} /> },
    { categoryId: 10, icon: <CategoryGlobalIcon {...svgProps} /> },
    { categoryId: 11, icon: <CategoryEconomyIcon {...svgProps} /> },
    { categoryId: 12, icon: <CategoryFinanceIcon {...svgProps} /> },
    { categoryId: 13, icon: <CategoryItIcon {...svgProps} /> },
    { categoryId: 14, icon: <CategoryScienceIcon {...svgProps} /> },
    { categoryId: 15, icon: <CategoryLiberalartsIcon {...svgProps} /> },
    { categoryId: 16, icon: <CategoryHealthIcon {...svgProps} /> },
    { categoryId: 17, icon: <CategoryTrendIcon {...svgProps} /> },
  ]
  return categoryIcons.find((v) => v.categoryId === categoryId)?.icon
}
