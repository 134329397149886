import * as React from 'react'
import { SVGProps } from 'react'
export const PdfCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64 10.667C34.56 10.667 10.667 34.56 10.667 64c0 29.44 23.893 53.333 53.333 53.333 29.44 0 53.333-23.893 53.333-53.333 0-29.44-23.893-53.333-53.333-53.333Z"
        fill="#fff"
      />
      <path
        d="M85.333 37.333h-32c-2.933 0-5.333 2.4-5.333 5.334v32C48 77.6 50.4 80 53.333 80h32c2.934 0 5.334-2.4 5.334-5.333v-32c0-2.934-2.4-5.334-5.334-5.334Zm-22.666 20c0 2.214-1.787 4-4 4H56v3.334c0 1.093-.907 2-2 2s-2-.907-2-2V53.333c0-1.466 1.2-2.666 2.667-2.666h4c2.213 0 4 1.786 4 4v2.666ZM76 62.667c0 2.213-1.787 4-4 4h-5.333a1.32 1.32 0 0 1-1.334-1.334V52a1.32 1.32 0 0 1 1.334-1.333H72c2.213 0 4 1.786 4 4v8Zm10.667-10c0 1.093-.907 2-2 2h-2v2.666h2c1.093 0 2 .907 2 2 0 1.094-.907 2-2 2h-2v3.334c0 1.093-.907 2-2 2-1.094 0-2-.907-2-2V53.333c0-1.466 1.2-2.666 2.666-2.666h3.334c1.093 0 2 .906 2 2ZM56 57.333h2.667v-2.666H56v2.666ZM40 48a2.674 2.674 0 0 0-2.667 2.667v34.666c0 2.934 2.4 5.334 5.334 5.334h34.666C78.8 90.667 80 89.467 80 88c0-1.467-1.2-2.667-2.667-2.667h-32a2.674 2.674 0 0 1-2.666-2.666v-32C42.667 49.2 41.467 48 40 48Zm29.333 14.667H72v-8h-2.667v8Z"
        fill="#F08D45"
      />
    </svg>
  )
}
