import * as React from 'react'
import { SVGProps } from 'react'
export const IconTry = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.32 54.613c-3.36 1.813-5.653 5.333-5.653 9.387 0 4.053 2.293 7.573 5.653 9.386 3.2 1.76 5.013 5.387 5.013 9.067V96c0 5.866-4.8 10.666-10.666 10.666H21.333c-5.866 0-10.666-4.8-10.666-10.666V82.453c0-3.68 1.813-7.307 5.013-9.067A10.614 10.614 0 0 0 21.333 64c0-4.054-2.24-7.574-5.6-9.44-3.253-1.707-5.013-5.334-5.013-9.014v-13.6a10.607 10.607 0 0 1 10.613-10.613h85.334c5.866 0 10.666 4.8 10.666 10.667v13.546c0 3.68-1.813 7.307-5.013 9.067Zm-60.824 1.21V51H34v4.824h5.832V76.2h5.832V55.824h5.832ZM53.201 76.2h5.832v-9.828h.252l6.66 9.828h7.272c-1.323-1.801-3.855-5.319-5.964-8.247l-.001-.001-1.63-2.264c2.735-1.08 5.22-3.24 5.22-7.02 0-5.544-5.293-7.668-9.433-7.668h-8.208v25.2Zm5.832-20.376h2.232c2.016 0 3.384 1.188 3.384 2.952 0 1.944-1.728 2.772-4.068 2.772h-1.548v-5.724ZM85.585 65.94V76.2h-5.832V65.724L71.797 51h7.164l3.924 8.496h.072L87.349 51h6.768l-8.532 14.94Z"
        fill="currentColor"
      />
    </svg>
  )
}
