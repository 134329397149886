import * as React from 'react'
import { SVGProps } from 'react'
export const IconBusiness = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64 37.333V26.667C64 20.8 59.2 16 53.334 16h-32c-5.867 0-10.667 4.8-10.667 10.667v74.666c0 5.867 4.8 10.667 10.667 10.667h85.333c5.867 0 10.667-4.8 10.667-10.667V48c0-5.867-4.8-10.667-10.667-10.667H64Zm-32 64H21.334V90.667H32v10.666ZM32 80H21.334V69.333H32V80Zm0-21.333H21.334V48H32v10.667Zm0-21.334H21.334V26.667H32v10.666Zm21.334 64H42.667V90.667h10.667v10.666Zm0-21.333H42.667V69.333h10.667V80Zm0-21.333H42.667V48h10.667v10.667Zm0-21.334H42.667V26.667h10.667v10.666Zm48 64H64V90.667h10.667V80H64V69.333h10.667V58.667H64V48h37.334c2.933 0 5.333 2.4 5.333 5.333V96c0 2.933-2.4 5.333-5.333 5.333ZM96 58.667H85.334v10.666H96V58.667ZM96 80H85.334v10.667H96V80Z"
        fill="currentColor"
      />
    </svg>
  )
}
