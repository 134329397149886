import * as React from 'react'
import { SVGProps } from 'react'
export const Facebook = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 12.5C25 5.596 19.404 0 12.5 0S0 5.596 0 12.5c0 6.239 4.571 11.41 10.547 12.348v-8.735H7.373V12.5h3.174V9.746c0-3.133 1.866-4.863 4.721-4.863 1.367 0 2.798.244 2.798.244v3.076H16.49c-1.552 0-2.037.964-2.037 1.953V12.5h3.467l-.554 3.613h-2.913v8.735C20.43 23.91 25 18.74 25 12.5Z"
        fill="#1877F2"
      />
      <path
        d="m17.366 16.113.554-3.613h-3.467v-2.344c0-.988.485-1.953 2.037-1.953h1.576V5.127s-1.43-.244-2.798-.244c-2.855 0-4.721 1.73-4.721 4.863V12.5H7.373v3.613h3.174v8.735c1.294.203 2.612.203 3.906 0v-8.735h2.913Z"
        fill="#fff"
      />
    </svg>
  )
}
