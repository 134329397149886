import * as React from 'react'
import { SVGProps } from 'react'
export const IconNavigateNext = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48 35.79a5.311 5.311 0 0 0 0 7.52l20.693 20.693L48 84.697a5.311 5.311 0 0 0 0 7.52 5.311 5.311 0 0 0 7.52 0L80 67.736a5.311 5.311 0 0 0 0-7.52l-24.48-24.48c-2.027-2.027-5.44-2.027-7.52.053Z"
        fill="currentColor"
      />
    </svg>
  )
}
