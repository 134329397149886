import * as React from 'react'
import { SVGProps } from 'react'
export const IconMoreHoriz = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 53.334c-5.867 0-10.667 4.8-10.667 10.666 0 5.867 4.8 10.667 10.667 10.667 5.866 0 10.666-4.8 10.666-10.667S37.866 53.334 32 53.334Zm64 0c-5.867 0-10.667 4.8-10.667 10.666 0 5.867 4.8 10.667 10.667 10.667 5.866 0 10.666-4.8 10.666-10.667S101.866 53.334 96 53.334Zm-32 0c-5.867 0-10.667 4.8-10.667 10.666 0 5.867 4.8 10.667 10.667 10.667 5.866 0 10.666-4.8 10.666-10.667S69.866 53.334 64 53.334Z"
        fill="currentColor"
      />
    </svg>
  )
}
