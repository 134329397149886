import * as React from 'react'
import { SVGProps } from 'react'
export const IconWatchLater = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64 10.666c-29.334 0-53.334 24-53.334 53.334 0 29.333 24 53.333 53.334 53.333 29.333 0 53.333-24 53.333-53.333 0-29.334-24-53.334-53.333-53.334Zm18.933 73.6L61.173 70.88a5.258 5.258 0 0 1-2.56-4.533V41.333c.053-2.186 1.867-4 4.053-4 2.187 0 4 1.814 4 4v23.734l20.48 12.32c1.92 1.173 2.56 3.68 1.387 5.6-1.173 1.866-3.68 2.453-5.6 1.28Z"
        fill="currentColor"
      />
    </svg>
  )
}
