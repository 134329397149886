import * as React from 'react'
import { SVGProps } from 'react'
export const IconReaction = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.992 2.36c-4.217 0-7.63 3.423-7.63 7.64 0 4.216 3.415 7.639 7.631 7.639 4.224 0 7.647-3.423 7.647-7.64 0-4.216-3.423-7.638-7.648-7.638ZM7.327 6.944a1.145 1.145 0 1 1 0 2.29 1.145 1.145 0 0 1 0-2.29Zm6.27 5.133c-.694 1.49-2.039 2.506-3.597 2.506-1.558 0-2.902-1.016-3.598-2.506a.383.383 0 0 1 .344-.55h6.508c.284 0 .467.298.344.55Zm-.924-2.842a1.144 1.144 0 1 1 0-2.29 1.144 1.144 0 1 1 0 2.29Z"
        fill="currentColor"
      />
    </svg>
  )
}
