import * as React from 'react'
import { SVGProps } from 'react'
export const IconBookmarkAdd = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.328 58.666v35.849a.874.874 0 0 1-1.218.803L59.349 84.721a1.747 1.747 0 0 0-1.376 0L33.212 95.317a.874.874 0 0 1-1.218-.803V28.414c0-.965.783-1.748 1.748-1.748h30.252V16h-32c-5.866 0-10.666 4.8-10.666 10.667v80.77c0 2.16 2.209 3.617 4.194 2.766l33.139-14.203L91.8 110.203c1.986.851 4.194-.606 4.194-2.766v-48.77H85.328Zm21.333-23.333a2 2 0 0 1-2 2h-8.667v8.666a2 2 0 0 1-2 2h-6.666a2 2 0 0 1-2-2v-8.666H76.66a2 2 0 0 1-2-2v-6.667a2 2 0 0 1 2-2h8.667V18a2 2 0 0 1 2-2h6.666a2 2 0 0 1 2 2v8.666h8.667a2 2 0 0 1 2 2v6.667Z"
        fill="currentColor"
      />
    </svg>
  )
}
