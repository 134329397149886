import { SVGProps } from 'react'

export const IconSearchBlueGradation = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7915 12.75C18.7915 16.0867 16.0866 18.7916 12.7498 18.7916C9.41312 18.7916 6.70817 16.0867 6.70817 12.75C6.70817 9.41324 9.41312 6.70829 12.7498 6.70829C16.0866 6.70829 18.7915 9.41324 18.7915 12.75ZM17.7739 19.3991C16.377 20.4562 14.6367 21.0833 12.7498 21.0833C8.14746 21.0833 4.4165 17.3523 4.4165 12.75C4.4165 8.14759 8.14746 4.41663 12.7498 4.41663C17.3522 4.41663 21.0832 8.14759 21.0832 12.75C21.0832 14.6392 20.4545 16.3816 19.395 17.7793L23.3372 21.7215C23.6626 22.047 23.6626 22.5746 23.3372 22.9L22.8952 23.342C22.5698 23.6674 22.0422 23.6674 21.7167 23.342L17.7739 19.3991Z"
        fill="url(#paint0_linear_374_45678)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_374_45678"
          x1="28.4467"
          y1="-2.39103"
          x2="5.65176"
          y2="24.6491"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.20622" stopColor="#408BED" />
          <stop offset="1" stopColor="#044DAB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
