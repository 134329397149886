interface Props {
  className?: string
  color?: string
}

export const Check: React.FC<Props> = ({ color = 'white', className = '' }) => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.82923 9.22917L1.35423 5.75417L0.170898 6.92917L4.82923 11.5875L14.8292 1.58751L13.6542 0.412506L4.82923 9.22917Z"
        fill={color}
      />
    </svg>
  )
}
