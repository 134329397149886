import * as React from 'react'
import { SVGProps } from 'react'
export const IconAddUser = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M80 64c11.786 0 21.333-9.547 21.333-21.333 0-11.787-9.547-21.334-21.333-21.334-11.787 0-21.334 9.547-21.334 21.334C58.666 54.453 68.213 64 80 64ZM32 53.334V42.666c0-2.934-2.4-5.334-5.334-5.334-2.933 0-5.333 2.4-5.333 5.334v10.666H10.666c-2.933 0-5.333 2.4-5.333 5.334 0 2.933 2.4 5.333 5.333 5.333h10.667v10.667c0 2.933 2.4 5.333 5.333 5.333C29.6 80 32 77.6 32 74.667V64h10.666C45.6 64 48 61.6 48 58.667c0-2.934-2.4-5.334-5.334-5.334H32Zm48 21.333c-14.24 0-42.667 7.147-42.667 21.333v5.333c0 2.934 2.4 5.334 5.333 5.334h74.667c2.933 0 5.333-2.4 5.333-5.334V96c0-14.186-28.426-21.333-42.666-21.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
