import * as React from 'react'
import { SVGProps } from 'react'
export const IconCircleSend = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.666 64c0-29.334 24-53.334 53.334-53.334 29.333 0 53.333 24 53.333 53.334 0 29.333-24 53.333-53.333 53.333-29.334 0-53.334-24-53.334-53.333Zm80.657 2.453L44.79 86.4c-1.76.773-3.707-.533-3.707-2.427l-.026-12.293c0-1.333.986-2.48 2.32-2.667L81.057 64l-37.68-5.04c-1.334-.16-2.32-1.307-2.32-2.64l.026-12.293c0-1.894 1.947-3.2 3.707-2.427l46.533 19.947c2.16.933 2.16 3.973 0 4.906Z"
        fill="currentColor"
      />
    </svg>
  )
}
