import * as React from 'react'
import { SVGProps } from 'react'
export const IconToday = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M101.333 21.333H96V16c0-2.934-2.4-5.334-5.333-5.334-2.934 0-5.334 2.4-5.334 5.334v5.333H42.667V16c0-2.934-2.4-5.334-5.334-5.334C34.4 10.666 32 13.066 32 16v5.333h-5.333c-5.92 0-10.614 4.8-10.614 10.667L16 106.667c0 5.866 4.747 10.666 10.667 10.666h74.666c5.867 0 10.667-4.8 10.667-10.666V32c0-5.867-4.8-10.667-10.667-10.667ZM96 106.667H32c-2.933 0-5.333-2.4-5.333-5.334V48h74.666v53.333c0 2.934-2.4 5.334-5.333 5.334Zm-53.333-48h16C61.6 58.666 64 61.066 64 64v16c0 2.933-2.4 5.333-5.333 5.333h-16c-2.934 0-5.334-2.4-5.334-5.333V64c0-2.934 2.4-5.334 5.334-5.334Z"
        fill="currentColor"
      />
    </svg>
  )
}
