import * as React from 'react'
import { SVGProps } from 'react'
export const IconAdd1 = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M96 69.333H69.333V96c0 2.933-2.4 5.333-5.333 5.333-2.934 0-5.334-2.4-5.334-5.333V69.333H32c-2.933 0-5.334-2.4-5.334-5.333 0-2.934 2.4-5.334 5.334-5.334h26.666V32c0-2.933 2.4-5.334 5.334-5.334 2.933 0 5.333 2.4 5.333 5.334v26.666H96c2.933 0 5.333 2.4 5.333 5.334 0 2.933-2.4 5.333-5.333 5.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
