import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategorySkillup = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_skillup_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M59.684 34.5c.185.295.519.48.89.48h9.372c.37 0 .667-.185.89-.48l7.631-11.697c.223-.332.223-.738.037-1.07a1.092 1.092 0 0 0-.926-.553H52.904c-.37 0-.74.221-.926.553-.185.332-.148.738.037 1.07L59.647 34.5h.037ZM69.946 37.489h-9.373c-.519 0-.963.369-1.037.922l-7.632 57.23c-.037.331.074.7.333.922l12.337 10.959c.186.184.445.258.704.258.26 0 .482-.074.704-.258l12.337-10.96c.26-.22.37-.59.333-.922l-7.631-57.229c-.075-.516-.52-.922-1.038-.922h-.037Z"
          fill="#0081CC"
        />
        <path
          d="M111.403 41.88c-.297-.296-.704-.37-1.112-.259l-25.044 7.085a1.185 1.185 0 0 0-.852 1.144c0 .516.407.959.926 1.07l8.076 1.623-30.675 26.53-10.707-9.741a1.1 1.1 0 0 0-1.519 0L21.784 94.238l.741.849 5.187 6.42-.074.074 1.556 1.697 21.228-19.63 10.781 9.557a1.18 1.18 0 0 0 1.519 0l39.122-33.872 1.89 7.822c.111.516.741.885 1.111.885.519 0 1.001-.369 1.112-.885l5.668-24.205c.074-.406 0-.812-.333-1.107l.111.037Z"
          fill="#00253F"
        />
      </g>
      <defs>
        <clipPath id="icon_category_skillup_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
