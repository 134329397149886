import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryTrend = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_trend_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M114.033 28.043a.936.936 0 0 0-.963-.222l-21.859 6.2c-.444.11-.74.553-.74.995 0 .443.333.85.814.923l7.04 1.402L69.39 64.019l-9.336-8.487a1.017 1.017 0 0 0-1.333 0L14.597 93.648l7.15 6.715 36.936-32.36 9.41 8.34c.371.332.927.332 1.334 0l36.307-33.098 1.63 6.826c.111.443.519.775.963.775.445 0 .853-.332.964-.775l4.927-21.106c.074-.332 0-.7-.296-.96l.111.038Z"
          fill="#0081CC"
        />
        <path
          d="M71.947 79.073c-1.853 1.624-4.52 1.624-6.299 0l-6.965-6.162-20.969 18.376c7.002 4.538 16.042 7.268 25.897 7.268 5.372 0 10.484-.811 15.152-2.287l11.744 6.826c.223.148.482.184.741.184a1.6 1.6 0 0 0 .964-.332c.481-.406.666-1.033.444-1.623l-3.445-9.815c8.78-5.572 14.411-13.985 14.411-23.32 0-5.018-1.63-9.704-4.445-13.873L71.947 79.11v-.037ZM56.312 52.765a4.714 4.714 0 0 1 6.261.073l6.817 6.2 17.116-15.756c-6.483-3.469-14.374-5.498-22.858-5.498-22.08 0-40.012 13.652-40.012 30.404 0 3.727.926 7.306 2.556 10.627L56.35 52.8l-.037-.036Z"
          fill="#00253F"
        />
      </g>
      <defs>
        <clipPath id="icon_category_trend_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
