import * as React from 'react'
import { SVGProps } from 'react'
export const IconSummaryTabbarEc = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50.654 66c-3.666 0-6.633 3-6.633 6.666 0 3.667 2.967 6.667 6.633 6.667 3.667 0 6.667-3 6.667-6.666 0-3.667-3-6.667-6.667-6.667Zm-20-50c0 1.833 1.5 3.333 3.334 3.333h3.333l12 25.3-4.5 8.133c-2.433 4.467.767 9.9 5.833 9.9h36.667c1.833 0 3.333-1.5 3.333-3.333 0-1.833-1.5-3.333-3.333-3.333H50.654l3.667-6.667h24.833c2.5 0 4.7-1.366 5.834-3.433L96.92 24.267c1.233-2.2-.367-4.934-2.9-4.934H44.688l-2.234-4.766c-.533-1.167-1.733-1.9-3-1.9h-5.466A3.343 3.343 0 0 0 30.654 16Zm53.334 50c-3.667 0-6.634 3-6.634 6.666 0 3.667 2.967 6.667 6.634 6.667 3.666 0 6.666-3 6.666-6.666 0-3.667-3-6.667-6.666-6.667ZM50.1 94.52h-1.98v-1.84h1.98v1.84Zm0 3.64h-1.98V96.3h1.98v1.86Zm0 3.64h-1.98v-1.88h1.98v1.88Zm2.08-11H46.1v12.9h6.08V90.8Zm-5.12 13.04c-.4 1.4-1.16 2.84-2.06 3.78.5.26 1.36.82 1.76 1.16.94-1.08 1.86-2.8 2.38-4.48l-2.08-.46Zm2.34.7c.64 1.04 1.36 2.46 1.64 3.38l1.84-.9c-.32-.88-1.06-2.22-1.72-3.22l-1.76.74Zm9.78-.68v-1h1.56v1h-1.56Zm-3.58-1h1.52v1H55.6v-1Zm1.52-2.38v.92H55.6v-.92h1.52Zm3.62 0v.92h-1.56v-.92h1.56Zm-3.62-4.88h2.04v.78h-2.04v-.78Zm0-2.32h2.04v.74h-2.04v-.74Zm6.7 10.58h-.92v-5.04h-3.72v-.76h4.62v-1.68h-2.52v-.78h1.84v-1.58h-1.84v-.74h2.16V91.6h-2.16v-1.52h-2.12v1.52h-2.04v-1.52h-2.08v1.52h-2.16v1.68h2.16v.74h-1.7v1.58h1.7v.78h-2.5v1.68h4.58v.76h-3.6v5.04h-1.08v1.66h1.08v3.24h2.08v-3.24h5.14v1.04c0 .22-.08.3-.34.3-.24 0-1.1 0-1.88-.02.26.52.54 1.34.62 1.9 1.3 0 2.24-.02 2.9-.34.66-.32.86-.82.86-1.82v-1.06h.92v-1.66ZM68.71 91.18v2.42h4.56c.06.68.14 1.4.28 2.16l-1.22-.22c-1.1 5.26-3.48 9.12-7.64 11.22.62.46 1.74 1.44 2.18 1.94 3.48-2.08 5.88-5.4 7.38-9.88 1.1 3.58 3.22 7.36 7.36 9.86.42-.62 1.4-1.66 1.92-2.08-7.36-4.34-7.9-11.64-7.9-15.42h-6.92Z"
        fill="currentColor"
      />
    </svg>
  )
}
