interface Props {
  className?: string
}

export const Ec: React.FC<Props> = ({ className = '' }) => {
  return (
    <img
      className={className}
      src={`${process.env.IMG_SERVER}/icon/ec.png`}
      alt=""
    />
  )
}
