interface Props {
  size?: number
  color?: string
  className?: string
}

export const ArrowRightSmall: React.FC<Props> = ({
  size,
  color,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 14}
      height={size || 20}
      viewBox="0 0 14 20"
      fill="none"
      stroke={color || '#222'}
      strokeWidth="0.1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`table-cell align-middle ${className || ''}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.07908 3.59775C3.40881 3.26129 3.9625 3.2431 4.31578 3.55713L10.8783 9.39046C11.0556 9.54807 11.1563 9.76869 11.1563 9.99968C11.1563 10.2307 11.0556 10.4513 10.8783 10.6089L4.31578 16.4422C3.9625 16.7563 3.40881 16.7381 3.07908 16.4016C2.74935 16.0652 2.76844 15.5378 3.12172 15.2238L8.99886 9.99968L3.12172 4.77556C2.76844 4.46153 2.74935 3.9342 3.07908 3.59775Z"
        fill={color}
      />
    </svg>
  )
}
