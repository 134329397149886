import * as React from 'react'
import { SVGProps } from 'react'
export const IconSummaryIndex = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 30.4C16 22.447 22.447 16 30.4 16h67.2c7.953 0 14.4 6.447 14.4 14.4v67.2c0 7.953-6.447 14.4-14.4 14.4H30.4c-7.953 0-14.4-6.447-14.4-14.4V30.4Zm26.4 12a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm12-6a4.8 4.8 0 0 0-4.8 4.8v2.4a4.8 4.8 0 0 0 4.8 4.8h38.4a4.8 4.8 0 0 0 4.8-4.8v-2.4a4.8 4.8 0 0 0-4.8-4.8H54.4ZM42.4 64a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm12-6a4.8 4.8 0 0 0-4.8 4.8v2.4a4.8 4.8 0 0 0 4.8 4.8h38.4a4.8 4.8 0 0 0 4.8-4.8v-2.4a4.8 4.8 0 0 0-4.8-4.8H54.4Zm-12 27.6a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm12-6a4.8 4.8 0 0 0-4.8 4.8v2.4a4.8 4.8 0 0 0 4.8 4.8h38.4a4.8 4.8 0 0 0 4.8-4.8v-2.4a4.8 4.8 0 0 0-4.8-4.8H54.4Z"
        fill="currentColor"
      />
    </svg>
  )
}
