import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategory = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M56.8 15.68 36.96 48.107c-2.187 3.52.373 8.106 4.533 8.106H81.12c4.16 0 6.72-4.586 4.533-8.106L65.867 15.68c-2.08-3.413-6.987-3.413-9.067 0ZM90.667 114.88c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24ZM18.667 112.213h32c2.933 0 5.333-2.4 5.333-5.333v-32c0-2.933-2.4-5.333-5.333-5.333h-32c-2.934 0-5.334 2.4-5.334 5.333v32c0 2.933 2.4 5.333 5.334 5.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
