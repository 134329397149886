import * as React from 'react'
import { SVGProps } from 'react'
export const IconSearch = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87 58c0 16.016-12.984 29-29 29S29 74.016 29 58s12.984-29 29-29 29 12.984 29 29Zm-4.884 31.916A39.823 39.823 0 0 1 58 98c-22.091 0-40-17.909-40-40s17.909-40 40-40 40 17.909 40 40a39.824 39.824 0 0 1-8.103 24.14l18.923 18.923a4 4 0 0 1 0 5.657l-2.122 2.122a4 4 0 0 1-5.656 0L82.116 89.916Z"
        fill="currentColor"
      />
    </svg>
  )
}
