import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryFinance = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_finance_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path d="M97.354 38.491H22.06v42.143h75.293V38.491Z" fill="#0081CC" />
        <path
          d="M98.83 82.125H25.043a4.487 4.487 0 0 1-4.474-4.473V41.474A4.487 4.487 0 0 1 25.043 37h69.329a4.487 4.487 0 0 1 4.473 4.474v40.651h-.015ZM25.043 39.983c-.82 0-1.491.67-1.491 1.49v36.179c0 .82.67 1.49 1.491 1.49h70.82V41.475c0-.82-.671-1.491-1.492-1.491H25.043Z"
          fill="#00253F"
        />
        <path
          d="M59.7 68.63a9.067 9.067 0 1 0 0-18.134 9.067 9.067 0 0 0 0 18.133Z"
          fill="#00253F"
        />
        <path
          d="M104.438 88.195H27.623v-3.072h73.743V44.516h3.072v43.679Z"
          fill="#00253F"
        />
        <path
          d="M110 94.205H33.17v-3.057h73.758V50.54H110v43.664Z"
          fill="#00253F"
        />
        <path
          d="M83.366 75.862H36.123l-.179-1.282c-.522-3.878-3.564-6.995-7.546-7.755l-1.208-.239V52.554l1.208-.239c3.982-.775 7.01-3.892 7.546-7.754l.179-1.283h47.243l.179 1.283c.537 3.832 3.55 6.919 7.486 7.694l1.208.239v14.152l-1.208.239c-3.937.775-6.95 3.877-7.486 7.694l-.18 1.283Zm-44.708-2.983h42.188c1.044-4.1 4.25-7.381 8.41-8.634V54.88c-4.16-1.253-7.352-4.533-8.41-8.634H38.658c-1.044 4.145-4.265 7.456-8.47 8.694v9.23c4.205 1.253 7.426 4.564 8.47 8.71Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="icon_category_finance_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
