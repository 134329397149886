import * as React from 'react'
import { SVGProps } from 'react'
export const IconSpeed = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M103.793 53.385a5.329 5.329 0 0 0-.373 5.333 40.3 40.3 0 0 1 2.773 9.653 42.665 42.665 0 0 1-3.68 25.227 5.334 5.334 0 0 1-4.747 2.827H30.3a5.333 5.333 0 0 1-4.746-2.88A42.667 42.667 0 0 1 69.34 32.37a41.01 41.01 0 0 1 11.253 2.987 5.333 5.333 0 0 0 5.333-.373 5.333 5.333 0 0 0-.907-9.387 53.333 53.333 0 0 0-67.146 75.787 10.67 10.67 0 0 0 9.173 5.333h73.867a10.675 10.675 0 0 0 9.28-5.333 53.342 53.342 0 0 0 2.933-47.414 5.334 5.334 0 0 0-9.333-.586Z"
        fill="currentColor"
      />
      <path
        d="M56.486 67.198A10.672 10.672 0 0 0 71.58 82.291l30.187-45.28-45.28 30.187Z"
        fill="currentColor"
      />
    </svg>
  )
}
