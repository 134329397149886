import * as React from 'react'
import { SVGProps } from 'react'
export const IconPlayArrowLarge = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27 23.776v80.448c0 6.134 6.689 9.862 11.84 6.523l62.585-40.224c4.767-3.029 4.767-10.017 0-13.123L38.84 17.253C33.69 13.914 27 17.642 27 23.776Z"
        fill="currentColor"
      />
    </svg>
  )
}
