import * as React from 'react'
import { SVGProps } from 'react'
export const IconFilterAlt = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.668 29.92C35.04 45.813 53.334 69.333 53.334 69.333V96c0 5.867 4.8 10.667 10.667 10.667s10.667-4.8 10.667-10.667V69.334s18.293-23.52 30.666-39.414c2.72-3.52.214-8.587-4.266-8.587H26.881c-4.427 0-6.933 5.067-4.213 8.587Z"
        fill="currentColor"
      />
    </svg>
  )
}
