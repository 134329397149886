/* eslint tailwindcss/no-custom-classname: off */
export const HelpCircle: React.FC = () => {
  return (
    <div className="help">
      <style jsx>{`
        .help::before {
          content: '？';
          font-size: 85%;
          display: inline-block;
          width: 1.5em;
          height: 1.5em;
          text-align: center;
          line-height: 1.5;
          border-radius: 50%;
          background: #e7e7e7;
          color: #666666;
          font-weight: bold;
        }
      `}</style>
    </div>
  )
}
