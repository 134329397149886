interface Props {
  size?: number
  color?: string
}

export const Try: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 44}
      height={size || 26}
      viewBox="0 0 44 26"
      fill="none"
      stroke={color || '#222'}
      strokeWidth="0.1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="table-cell align-middle"
    >
      <path
        d="M18.2018 9.01758H16.5508V12.4776H18.2018C19.6618 12.4776 20.3768 12.0476 20.3768 10.7566C20.3768 9.46558 19.6638 9.01758 18.2018 9.01758Z"
        fill={color}
      />
      <path
        d="M36.582 0.470703H2.266C1.66502 0.470703 1.08865 0.709442 0.663696 1.1344C0.238739 1.55936 0 2.13572 0 2.7367V23.2627C0 23.8637 0.238739 24.44 0.663696 24.865C1.08865 25.29 1.66502 25.5287 2.266 25.5287H41.734C42.335 25.5287 42.9113 25.29 43.3363 24.865C43.7613 24.44 44 23.8637 44 23.2627V7.8887L36.582 0.470703ZM12.99 9.0177H10.04V18.7707H8.313V9.0177H5.348V7.3457H12.99V9.0177ZM20.526 18.7707L18.544 14.1367H16.544V18.7707H14.82V7.3457H18.32C20.689 7.3457 22.103 8.4887 22.103 10.7547C22.1657 11.3978 22.0182 12.044 21.6827 12.5962C21.3472 13.1484 20.8417 13.577 20.242 13.8177L22.432 18.7667L20.526 18.7707ZM27.9 13.9977V18.7637H26.168V13.9977L22.968 7.3447H24.979L27.035 11.9117L29.1 7.3457H31.1L27.9 13.9977ZM37.317 21.7247H35.852V19.1997H37.314L37.317 21.7247ZM37.317 17.2937H35.852V14.7707H37.314L37.317 17.2937ZM37.317 12.7627H35.852V10.2367H37.314L37.317 12.7627ZM37.317 8.2327H35.852V5.7057H37.314L37.317 8.2327Z"
        fill={color}
      />
    </svg>
  )
}
