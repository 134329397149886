import * as React from 'react'
import { SVGProps } from 'react'
export const IconCircleFavorite = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m64 78.719 11.067 6.693c2.027 1.227 4.507-.586 3.973-2.88l-2.933-12.586 9.787-8.48c1.786-1.547.826-4.48-1.52-4.667l-12.88-1.093-5.04-11.894c-.907-2.16-4-2.16-4.907 0l-5.04 11.867-12.88 1.093c-2.347.187-3.307 3.12-1.52 4.667l9.787 8.48-2.934 12.587c-.533 2.293 1.947 4.106 3.974 2.88L64 78.719Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 64.335c0 27.8 22.536 50.335 50.335 50.335 27.8 0 50.335-22.536 50.335-50.335C114.67 36.535 92.134 14 64.335 14 36.535 14 14 36.536 14 64.335ZM64.335 11C34.879 11 11 34.879 11 64.335s23.879 53.335 53.335 53.335 53.335-23.879 53.335-53.335S93.791 11 64.335 11Z"
        fill="currentColor"
      />
    </svg>
  )
}
