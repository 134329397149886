import * as React from 'react'
import { SVGProps } from 'react'
export const IconDropdownBelow = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.343 58.343 13.657 13.657C8.617 8.617 12.187 0 19.314 0h89.372c7.128 0 10.697 8.617 5.657 13.657L69.657 58.343a8 8 0 0 1-11.314 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
