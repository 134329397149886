import * as React from 'react'
import { SVGProps } from 'react'
export const IconPlayCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64 10.666C34.56 10.666 10.667 34.56 10.667 64c0 29.44 23.893 53.333 53.333 53.333 29.44 0 53.334-23.893 53.334-53.333 0-29.44-23.894-53.334-53.334-53.334ZM50.667 78.24V49.76c0-4.213 4.693-6.773 8.213-4.48l22.134 14.24c3.253 2.08 3.253 6.88 0 8.96L58.88 82.72c-3.52 2.293-8.213-.267-8.213-4.48Z"
        fill="currentColor"
      />
    </svg>
  )
}
