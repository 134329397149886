import * as React from 'react'
import { SVGProps } from 'react'
export const IconFileCopy = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M82.666 5.333H24c-5.867 0-10.667 4.8-10.667 10.667v69.334c0 2.933 2.4 5.333 5.333 5.333 2.934 0 5.334-2.4 5.334-5.334v-64C24 18.4 26.4 16 29.333 16h53.333C85.6 16 88 13.6 88 10.667c0-2.933-2.4-5.333-5.334-5.333Zm3.147 24.48 25.76 25.76a10.657 10.657 0 0 1 3.093 7.52V112c0 5.867-4.8 10.667-10.666 10.667H45.28c-5.867 0-10.614-4.8-10.614-10.667l.054-74.666c0-5.867 4.746-10.667 10.613-10.667H78.24a10.7 10.7 0 0 1 7.573 3.146ZM82.666 64h24L77.333 34.667v24c0 2.933 2.4 5.333 5.333 5.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
