import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryManagement = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_management_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M48.366 84.101c.636-8.844 9.121-24.009 12.906-28.342 5.247-5.995 5.04-9.482 1.153-9.75-4.406-.31-12.935 3.993-13.955 5.017-2.055 2.032-5.559 3.576-7.983 1.053-2.41-2.523-3.43-7.3.414-11.174 3.592-3.62 11.634-12.02 16.202-14.512 3.548-2.36 3.489-3.784 2.158-5.209-.857-.92-2.365-2.092-3.208-2.849-.798-.727-.31-1.454.873-1.29 4.508.593 11.427 2.374 16.615 3.961 5.174 1.603 13.334 6.96 15.005 15.062 1.685 8.117.103 20.76-2.942 27.927-3.045 7.167-2.1 20.121-2.1 20.121H48.367v-.015ZM85.589 99.089c0-1.914 2.513-2.983 2.513-5.372 0-2.404-4.302-2.107-4.302-2.107H47.272s-4.301-.282-4.301 2.107c0 2.404 2.513 3.458 2.513 5.372 0 1.914-2.617 1.914-2.617 3.353 0 1.44.518 2.493 2.838 2.493h39.677c2.306 0 2.838-1.053 2.838-2.493 0-1.439-2.631-1.439-2.631-3.353ZM86.24 89.666H44.7a1.609 1.609 0 0 1-1.611-1.617c0-.89.724-1.618 1.611-1.618h41.54c.901 0 1.61.727 1.61 1.618 0 .89-.724 1.617-1.61 1.617ZM86.24 110.158H44.7a1.609 1.609 0 0 1-1.611-1.617c0-.89.724-1.617 1.611-1.617h41.54c.901 0 1.61.727 1.61 1.617s-.724 1.617-1.61 1.617Z"
          fill="#00253F"
        />
        <path
          d="M81.687 30.815c8.53 17.406-6.46 31.874-2.913 49.577H64.953c2.616-17.421 21.434-33.937 16.734-49.577Z"
          fill="#0081CC"
        />
        <path
          d="M56.32 38.502a2.547 2.547 0 0 0 2.542-2.553 2.547 2.547 0 0 0-2.543-2.552 2.547 2.547 0 0 0-2.542 2.552 2.547 2.547 0 0 0 2.542 2.553Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="icon_category_management_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
