import { SVGProps } from 'react'

export const IconTieBlueGradation = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="22"
      viewBox="0 0 8 22"
      fill="none"
      {...props}
    >
      <path
        d="M2.6388 3.48971C2.68412 3.56193 2.7657 3.60707 2.85634 3.60707H5.1496C5.24024 3.60707 5.31276 3.56193 5.36714 3.48971L7.23438 0.627931C7.28877 0.546682 7.28877 0.447377 7.24344 0.366128C7.19812 0.284879 7.10748 0.230713 7.01684 0.230713H0.980036C0.889393 0.230713 0.798751 0.284879 0.753429 0.366128C0.708108 0.447377 0.717172 0.546682 0.762494 0.627931L2.62973 3.48971H2.6388Z"
        fill="url(#paint0_linear_374_45715)"
      />
      <path
        d="M5.14256 4.57141H2.8493C2.7224 4.57141 2.61363 4.66169 2.5955 4.7971L0.728262 18.799C0.719198 18.8803 0.746391 18.9706 0.809841 19.0247L3.82824 21.706C3.87356 21.7511 3.93701 21.7691 4.00046 21.7691C4.06391 21.7691 4.1183 21.7511 4.17268 21.706L7.19108 19.0247C7.25453 18.9706 7.28173 18.8803 7.27266 18.799L5.40542 4.7971C5.3873 4.67072 5.27852 4.57141 5.15162 4.57141H5.14256Z"
        fill="url(#paint1_linear_374_45715)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_374_45715"
          x1="8.93989"
          y1="-0.968334"
          x2="5.96322"
          y2="5.88624"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.20622" stopColor="#408BED" />
          <stop offset="1" stopColor="#044DAB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_374_45715"
          x1="8.93661"
          y1="-1.53604"
          x2="-6.63295"
          y2="5.49751"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.20622" stopColor="#408BED" />
          <stop offset="1" stopColor="#044DAB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
