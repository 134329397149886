import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryProductivity = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_productivity_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M63.675 29.112c-18.966 0-34.4 15.491-34.4 34.53 0 19.038 15.434 34.53 34.4 34.53s34.399-15.492 34.399-34.53c0-19.039-15.418-34.53-34.4-34.53Z"
          fill="#fff"
        />
        <path
          d="M63.675 103.202c-21.73 0-39.41-17.747-39.41-39.56 0-21.813 17.68-39.56 39.41-39.56 21.73 0 39.41 17.747 39.41 39.56 0 21.813-17.68 39.56-39.41 39.56Zm0-74.669c-19.292 0-34.976 15.744-34.976 35.109 0 19.364 15.684 35.109 34.976 35.109 19.291 0 34.975-15.745 34.975-35.11 0-19.364-15.684-35.108-34.975-35.108Z"
          fill="#00253F"
        />
        <path
          d="M63.675 93.186c-16.231 0-29.432-13.251-29.432-29.544 0-12.346 7.76-23.505 19.306-27.749l1.064 2.938C44.28 42.63 37.347 52.601 37.347 63.657c0 14.571 11.811 26.428 26.328 26.428s26.328-11.857 26.328-26.428a26.29 26.29 0 0 0-2.07-10.284l2.868-1.216a29.42 29.42 0 0 1 2.306 11.5c0 16.278-13.2 29.529-29.432 29.529Z"
          fill="#00253F"
        />
        <path
          d="M63.675 66.61a2.969 2.969 0 0 1-2.956-2.968V35.24h5.912v22.392l14.561-11.174 3.592 4.72-19.306 14.808a2.925 2.925 0 0 1-1.803.624Z"
          fill="#0081CC"
        />
      </g>
      <defs>
        <clipPath id="icon_category_productivity_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
