import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryHealth = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_health_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M48.688 70.36v8.132c0 1.454.044 2.923-.015 4.377-.089 2.33-1.272 3.532-3.563 3.606-1.523.06-3.06.015-4.582.015-3.859 0-4.465-.49-5.248-4.392-2.957-.623-7.022 1.365-7.702-3.398-2.336-.46-5.115-.238-6.534-1.529-1.316-1.187-1.168-4.006-1.73-6.395-.103-.015-.606-.09-1.108-.09-1.966 0-2.72-1.023-2.646-2.908.059-1.766.014-3.531.014-5.297 0-4.215 0-4.215 3.755-4.615.532-2.256.414-5.06 1.744-6.41 1.272-1.277 4.066-1.024 6.475-1.484.533-4.808 4.716-2.805 7.613-3.384 1.242-4.288 1.405-4.422 5.854-4.422 1.301 0 2.602-.03 3.903.015 2.557.074 3.74 1.262 3.77 3.873.044 4.051.014 8.087.014 12.257l-.014 12.05Zm31.28-12.079v-8.146c0-1.454-.045-2.923.014-4.378.089-2.33 1.272-3.531 3.563-3.605 1.523-.06 3.06-.015 4.583-.015 3.858 0 4.45.49 5.292 4.614 1.567 0 3.34-.385 4.834.12 1.286.43 2.247 1.869 3.799 3.264 1.36.267 4.154.044 5.558 1.335 1.316 1.202 1.168 4.007 1.73 6.396.103.015.606.089 1.108.089 1.966 0 2.72 1.024 2.646 2.908-.059 1.766-.014 3.532-.014 5.298 0 4.214 0 4.214-3.755 4.615-.532 2.255-.414 5.06-1.759 6.41-1.272 1.276-4.065 1.01-6.475 1.484-.547 4.793-4.716 2.82-7.628 3.383-1.212 4.289-1.404 4.422-5.839 4.422-1.3 0-2.602.03-3.903-.015-2.557-.074-3.725-1.26-3.77-3.873-.058-3.991-.058-7.983-.103-11.96l.118-12.346ZM39.27 45.801v37.024h5.647V45.802H39.27ZM89.473 82.9V45.817h-5.706v37.082h5.706ZM31.392 50.27v28.06h3.902v-28.06h-3.902Zm61.924 0v28.06h3.903v-28.06h-3.903ZM23.527 74.796a470.3 470.3 0 0 1 3.977-.178V54.082h-3.977v20.715Zm81.541-.148V54.037h-3.917V74.65h3.917Z"
          fill="#00253F"
        />
        <path
          d="M39.271 45.802h5.647v37.023h-5.647V45.802ZM89.473 82.9h-5.706V45.816h5.706v37.082Z"
          fill="#0081CC"
        />
        <path
          d="M31.392 50.268h3.902v28.06h-3.902v-28.06ZM93.316 50.268h3.903v28.06h-3.903v-28.06Z"
          fill="#fff"
        />
        <path
          d="M23.527 74.797V54.082h3.977v20.537c-1.153.045-2.425.104-3.977.178ZM105.068 74.649h-3.917V54.037h3.917V74.65Z"
          fill="#0081CC"
        />
        <path d="M80.041 61.338H48.643v5.936h31.398v-5.936Z" fill="#00253F" />
      </g>
      <defs>
        <clipPath id="icon_category_health_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
