import * as React from 'react'
import { SVGProps } from 'react'
export const IconShoppingCart = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.647 96c-5.867 0-10.613 4.8-10.613 10.667 0 5.866 4.746 10.666 10.613 10.666 5.867 0 10.667-4.8 10.667-10.666 0-5.867-4.8-10.667-10.667-10.667Zm-32-80c0 2.933 2.4 5.333 5.333 5.333h5.334l19.2 40.48-7.2 13.013c-3.894 7.147 1.226 15.84 9.333 15.84h58.667c2.933 0 5.333-2.4 5.333-5.333 0-2.933-2.4-5.333-5.333-5.333H42.647l5.867-10.667h39.733c4 0 7.52-2.186 9.333-5.493l19.094-34.614c1.973-3.52-.587-7.893-4.64-7.893H33.1l-3.573-7.626c-.853-1.867-2.773-3.04-4.8-3.04H15.98c-2.933 0-5.333 2.4-5.333 5.333ZM95.98 96c-5.866 0-10.613 4.8-10.613 10.667 0 5.866 4.747 10.666 10.613 10.666 5.867 0 10.667-4.8 10.667-10.666 0-5.867-4.8-10.667-10.667-10.667Z"
        fill="currentColor"
      />
    </svg>
  )
}
