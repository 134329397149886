import * as React from 'react'
import { SVGProps } from 'react'
export const IconLogout = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.223 26.667h32c2.934 0 5.334-2.4 5.334-5.334 0-2.933-2.4-5.333-5.334-5.333h-32c-5.866 0-10.666 4.8-10.666 10.667v74.666c0 5.867 4.8 10.667 10.666 10.667h32c2.934 0 5.334-2.4 5.334-5.333 0-2.934-2.4-5.334-5.334-5.334h-32V26.667Z"
        fill="currentColor"
      />
      <path
        d="m110.69 62.133-14.88-14.88c-1.707-1.706-4.587-.533-4.587 1.867v9.547H53.89c-2.933 0-5.333 2.4-5.333 5.333 0 2.933 2.4 5.333 5.333 5.333h37.333v9.547c0 2.4 2.88 3.573 4.534 1.867l14.88-14.88a2.625 2.625 0 0 0 .053-3.734Z"
        fill="currentColor"
      />
    </svg>
  )
}
