interface Props {
  className?: string
  width?: number
  height?: number
}

export const New: React.FC<Props> = ({
  className = '',
  width = 50,
  height = 50,
}) => {
  return (
    <img
      src={`${process.env.IMG_SERVER}/icon/new.png`}
      width={width}
      height={height}
      className={className}
    />
  )
}
