import * as React from 'react'
import { SVGProps } from 'react'
export const IconUpdate = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.659 46.658v19.627c0 1.866 1.013 3.626 2.613 4.586l16.64 9.867c1.92 1.12 4.373.48 5.493-1.387 1.12-1.92.534-4.373-1.386-5.493l-15.307-9.12V46.605c-.053-2.134-1.867-3.947-4.053-3.947-2.187 0-4 1.813-4 4Zm53.333 4V22.445c0-2.4-2.88-3.574-4.533-1.867l-9.494 9.493c-9.653-9.653-23.413-15.2-38.453-13.866-22.347 2.026-40.747 20-43.2 42.346-3.2 28.907 19.36 53.44 47.68 53.44 24.48 0 44.693-18.346 47.627-42.026.373-3.2-2.134-5.974-5.334-5.974-2.666 0-4.906 1.974-5.226 4.587-2.294 18.613-18.347 33.013-37.6 32.747-19.787-.267-36.48-16.96-36.8-36.8-.32-20.8 16.586-37.867 37.333-37.867 10.293 0 19.627 4.213 26.4 10.933L79.245 48.738c-1.706 1.707-.533 4.587 1.867 4.587h28.213a2.64 2.64 0 0 0 2.667-2.667Z"
        fill="currentColor"
      />
    </svg>
  )
}
