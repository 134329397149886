import * as React from 'react'
import { SVGProps } from 'react'
export const IconFileDownload = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M88.48 50.666H80V24c0-2.933-2.4-5.334-5.334-5.334H53.334C50.4 18.666 48 21.066 48 24v26.666h-8.48c-4.747 0-7.147 5.76-3.787 9.12l24.48 24.48a5.311 5.311 0 0 0 7.52 0l24.48-24.48c3.36-3.36 1.014-9.12-3.733-9.12ZM26.666 104c0 2.933 2.4 5.333 5.334 5.333h64c2.933 0 5.333-2.4 5.333-5.333 0-2.933-2.4-5.334-5.333-5.334H32c-2.933 0-5.334 2.401-5.334 5.334Z"
        fill="currentColor"
      />
    </svg>
  )
}
