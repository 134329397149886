import * as React from 'react'
import { SVGProps } from 'react'
export const IconNew = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="108"
      height="108"
      viewBox="0 0 108 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.9998 0.666748C24.5598 0.666748 0.666504 24.5601 0.666504 54.0001C0.666504 83.4401 24.5598 107.333 53.9998 107.333C83.4398 107.333 107.333 83.4401 107.333 54.0001C107.333 24.5601 83.4398 0.666748 53.9998 0.666748ZM36.744 66.2V41H30.912V55.688L20.148 41H15V66.2H20.832V51.584L31.488 66.2H36.744ZM57.4194 66.2V61.376H46.6914V55.652H57.4194V50.828H46.6914V45.824H57.4194V41H40.8594V66.2H57.4194ZM73.7883 41H79.2243L83.2562 56.048H83.3283L87.2162 41H93.7323L86.4963 66.2H80.7363L76.3802 50.648H76.3083L71.8443 66.2H66.3363L59.2443 41H65.7243L69.5042 56.048H69.5762L73.7883 41Z"
        fill="currentColor"
      />
    </svg>
  )
}
