import * as React from 'react'
import { SVGProps } from 'react'
export const IconHeadphones = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 64v37.333C16 107.2 20.8 112 26.667 112h10.666C43.2 112 48 107.2 48 101.333V80c0-5.867-4.8-10.667-10.667-10.667H26.667V64c0-20.64 16.693-37.333 37.333-37.333 20.64 0 37.333 16.693 37.333 37.333v5.333H90.667C84.8 69.333 80 74.133 80 80v21.333C80 107.2 84.8 112 90.667 112h10.666C107.2 112 112 107.2 112 101.333V64c0-26.507-21.493-48-48-48S16 37.493 16 64Z"
        fill="currentColor"
      />
    </svg>
  )
}
