import * as React from 'react'
import { SVGProps } from 'react'
export const IconContentCopy = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M82.666 106.667H29.334V37.333C29.333 34.4 26.933 32 24 32c-2.933 0-5.334 2.4-5.334 5.333v69.334c0 5.866 4.8 10.666 10.667 10.666h53.334c2.933 0 5.333-2.4 5.333-5.333 0-2.933-2.4-5.333-5.334-5.333Zm26.667-21.334v-64c0-5.866-4.8-10.666-10.666-10.666h-48C44.8 10.666 40 15.466 40 21.332v64C40 91.2 44.8 96 50.666 96h48c5.867 0 10.667-4.8 10.667-10.667Zm-10.666 0h-48v-64h48v64Z"
        fill="currentColor"
      />
    </svg>
  )
}
