import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryMasterpiece = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_masterpiece_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M52.893 21.334a4 4 0 0 0-4 4V80h-12a4 4 0 0 0-4 4v12c0 5.867 4.8 10.667 10.667 10.667h53.333c8.853 0 16-7.147 16-16V25.333a4 4 0 0 0-4-4h-56Zm49.333 69.333c0 2.933-2.4 5.333-5.333 5.333-2.933 0-5.333-2.4-5.333-5.333V84a4 4 0 0 0-4-4h-28V32h42.666v58.667Z"
          fill="#00253F"
        />
        <path
          d="M59.56 35.307s-10.667-9.28-24.587-9.28c-5.908 0-19.318 5.765-24.93 8.3-1.25.566-1.543 2.194-.582 3.174l16.926 17.273h5.92v5.92c6.693 7.253 16.586 7.253 16.586 7.253s5.07.146 8.107-.8c8.5-2.647 11.947-7.52 11.947-7.52l14.508 14.457a2 2 0 0 0 1.411.583h4.694a2 2 0 0 0 2-2v-4.689a2 2 0 0 0-.59-1.417L59.56 35.306ZM42.973 55.52V44.053h-12.16l-5.546-5.546a27.039 27.039 0 0 1 9.706-1.813c7.147 0 13.814 2.773 18.88 7.786l7.52 7.52-1.066 1.067c-2.72 2.72-6.347 4.267-10.24 4.267-2.507 0-4.96-.64-7.094-1.814Z"
          fill="#0081CC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m61.605 33.11 31.482 31.324a5 5 0 0 1 1.473 3.544v4.689a5 5 0 0 1-5 5h-4.694a5 5 0 0 1-3.529-1.458L69.002 63.917c-.291.26-.61.533-.96.814-2.185 1.76-5.502 3.833-10.15 5.28-1.9.592-4.245.803-5.935.887a47.405 47.405 0 0 1-3.072.05l-.041-.001h-.014l-.121-.003a23.676 23.676 0 0 1-1.793-.129 29.91 29.91 0 0 1-4.598-.881c-3.649-1-8.367-3.035-12.216-7.206l-.795-.862v-4.093h-4.18L7.318 39.602c-2.394-2.442-1.696-6.568 1.49-8.007 2.834-1.28 7.664-3.39 12.555-5.184 2.444-.897 4.942-1.73 7.238-2.341 2.246-.6 4.495-1.042 6.371-1.042 7.612 0 14.248 2.53 18.911 4.985a48.302 48.302 0 0 1 5.536 3.404 37.13 37.13 0 0 1 1.523 1.145 24.501 24.501 0 0 1 .532.436l.035.03.012.01.004.004.002.001s.001.002-1.968 2.265c0 0-10.667-9.28-24.587-9.28-5.908 0-19.318 5.765-24.93 8.3-1.25.566-1.543 2.194-.582 3.174l16.926 17.273h5.92v5.92c6.693 7.253 16.586 7.253 16.586 7.253s5.07.146 8.107-.8c4.816-1.5 8.01-3.714 9.873-5.351 1.426-1.254 2.074-2.17 2.074-2.17l14.508 14.458a2 2 0 0 0 1.411.583h4.694a2 2 0 0 0 2-2v-4.689a2 2 0 0 0-.59-1.417L59.56 35.306l1.97-2.264.075.066ZM42.973 44.053V55.52a14.766 14.766 0 0 0 7.094 1.813c3.386 0 6.57-1.17 9.133-3.264a14.97 14.97 0 0 0 1.107-1.002L61.373 52l-7.52-7.52c-5.066-5.013-11.733-7.786-18.88-7.786a26.947 26.947 0 0 0-9.706 1.813l5.546 5.547h12.16Zm-10.917-3h10.882a23.753 23.753 0 0 0-7.965-1.36c-1.329 0-2.65.112-3.946.33l1.03 1.03Zm13.917 1.326V53.6c1.29.478 2.681.733 4.094.733a11.35 11.35 0 0 0 6.998-2.399l-5.322-5.321-.005-.006a24.115 24.115 0 0 0-5.765-4.227Z"
          fill="#E4E6E9"
        />
        <path d="M48 62.5 22 38l24-6 19.5 19.5-17.5 11Z" fill="#fff" />
        <path
          d="M59.56 35.307s-10.667-9.28-24.587-9.28c-5.908 0-19.318 5.765-24.93 8.3-1.25.566-1.543 2.194-.582 3.174l16.926 17.273h5.92v5.92c6.693 7.253 16.586 7.253 16.586 7.253s5.07.146 8.107-.8c8.5-2.647 11.947-7.52 11.947-7.52l14.508 14.457a2 2 0 0 0 1.411.583h4.694a2 2 0 0 0 2-2v-4.689a2 2 0 0 0-.59-1.417L59.56 35.306ZM42.973 55.52V44.053h-12.16l-5.546-5.546a27.039 27.039 0 0 1 9.706-1.813c7.147 0 13.814 2.773 18.88 7.786l7.52 7.52-1.066 1.067c-2.72 2.72-6.347 4.267-10.24 4.267-2.507 0-4.96-.64-7.094-1.814Z"
          fill="#0081CC"
        />
      </g>
      <defs>
        <clipPath id="icon_category_masterpiece_svg__a">
          <rect width={128} height={128} rx={16} fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  )
}
