interface Props {
  width?: number
  height?: number
}

export const PportraitCircle: React.FC<Props> = ({
  width = 30,
  height = 30,
}) => {
  return (
    <img
      className="rounded-full"
      src={`${process.env.IMG_SERVER}/user/0000000000.jpg`}
      width={width}
      height={height}
    />
  )
}
