import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryEconomy = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_economy_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M88.9 72.154V63.43h-8.983V48.015h-31.85v15.416h-8.982v8.723H24.016v24.538h79.968V72.154H88.899Z"
          fill="#fff"
        />
        <path
          d="M79.886 38.985H48.067v4.169h31.82v-4.17ZM74.049 32.492H53.92v4.17H74.05v-4.17ZM70.593 26H57.36v4.17h13.232V26Z"
          fill="#00253F"
        />
        <path
          d="M74.22 53.57h-4.67v22.769h4.67v-22.77ZM66.311 53.57h-4.67v22.769h4.67v-22.77ZM58.404 53.57h-4.67v22.769h4.67v-22.77Z"
          fill="#0081CC"
        />
        <path
          d="M106.319 99H21.681V75.23c0-2.968 2.444-5.384 5.449-5.384h9.62v-3.338c0-2.97 2.444-5.385 5.448-5.385h3.534V45.708h36.52v15.415h3.534c3.004 0 5.448 2.416 5.448 5.385v3.338h9.621c3.004 0 5.448 2.416 5.448 5.385V99h.016Zm-79.968-4.615h75.298V75.23c0-.43-.343-.77-.779-.77H86.58v-7.953c0-.431-.343-.77-.778-.77h-8.204V50.324H50.402V65.74h-8.204a.766.766 0 0 0-.778.769v7.954H27.13a.776.776 0 0 0-.779.769v19.154Z"
          fill="#00253F"
        />
      </g>
      <defs>
        <clipPath id="icon_category_economy_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
