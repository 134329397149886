import * as React from 'react'
import { SVGProps } from 'react'
export const IconChangeCircle = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64 10.666C34.56 10.666 10.666 34.56 10.666 64c0 29.44 23.894 53.333 53.334 53.333 29.44 0 53.333-23.893 53.333-53.333 0-29.44-23.893-53.334-53.333-53.334ZM68.853 96.8c-1.653 1.653-4.533.48-4.533-1.867v-4.267H64a26.785 26.785 0 0 1-18.88-7.786c-7.627-7.627-9.653-18.773-6.08-28.267 1.013-2.72 4.587-3.413 6.613-1.333 1.174 1.173 1.44 2.88.907 4.373-2.454 6.613-1.067 14.294 4.267 19.627 3.733 3.733 8.64 5.493 13.546 5.386v-5.013c0-2.4 2.88-3.573 4.533-1.867l8.64 8.64a2.64 2.64 0 0 1 0 3.787L68.853 96.8Zm13.494-22.027c-1.174-1.173-1.44-2.88-.907-4.373 2.453-6.614 1.067-14.294-4.267-19.627a18.465 18.465 0 0 0-13.493-5.44v5.014c0 2.4-2.88 3.573-4.533 1.866l-8.694-8.587a2.64 2.64 0 0 1 0-3.786l8.64-8.64c1.653-1.653 4.533-.48 4.533 1.866v4.32c6.934-.106 13.92 2.4 19.2 7.734 7.627 7.627 9.654 18.773 6.08 28.267-1.013 2.773-4.533 3.466-6.56 1.386Z"
        fill="currentColor"
      />
    </svg>
  )
}
