interface Props {
  width?: number
  height?: number
  className?: string
}

export const ShareTwitter: React.FC<Props> = ({
  width = 30,
  height = 30,
  className = '',
}) => {
  return (
    <img
      src={`${process.env.IMG_SERVER}/common/X_round.svg`}
      width={width}
      height={height}
      className={className}
      alt="twitter"
    />
  )
}
