import * as React from 'react'
import { SVGProps } from 'react'
export const AudioCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64 10.667C34.56 10.667 10.667 34.56 10.667 64c0 29.44 23.893 53.333 53.333 53.333 29.44 0 53.333-23.893 53.333-53.333 0-29.44-23.893-53.333-53.333-53.333Z"
        fill="#fff"
      />
      <path
        d="M40 62v18.667C40 83.6 42.4 86 45.333 86h5.334C53.6 86 56 83.6 56 80.667V70c0-2.933-2.4-5.333-5.333-5.333h-5.334V62c0-10.32 8.347-18.667 18.667-18.667 10.32 0 18.667 8.347 18.667 18.667v2.667h-5.334C74.4 64.667 72 67.067 72 70v10.667C72 83.6 74.4 86 77.333 86h5.334C85.6 86 88 83.6 88 80.667V62c0-13.253-10.747-24-24-24S40 48.747 40 62Z"
        fill="#479EC3"
      />
    </svg>
  )
}
