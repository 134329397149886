interface Props {
  size?: number
  color?: string
}

export const Clipboard: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 17}
      height={size || 16}
      viewBox="0 0 17 19"
      fill="none"
      stroke={color || '#222'}
      strokeWidth="0.1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="table-cell align-middle"
    >
      <path
        d="M12.2507 0.362305H2.25065C1.33398 0.362305 0.583984 1.1123 0.583984 2.02897V13.6956H2.25065V2.02897H12.2507V0.362305ZM14.7507 3.69564H5.58398C4.66732 3.69564 3.91732 4.44564 3.91732 5.3623V17.029C3.91732 17.9456 4.66732 18.6956 5.58398 18.6956H14.7507C15.6673 18.6956 16.4173 17.9456 16.4173 17.029V5.3623C16.4173 4.44564 15.6673 3.69564 14.7507 3.69564ZM14.7507 17.029H5.58398V5.3623H14.7507V17.029Z"
        fill={color}
      />
    </svg>
  )
}
