import * as React from 'react'
import { SVGProps } from 'react'
export const IconHome = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M53.321 103.08V76.413h21.334v26.667c0 2.933 2.4 5.333 5.333 5.333h16c2.933 0 5.333-2.4 5.333-5.333V65.746h9.067c2.453 0 3.626-3.04 1.76-4.64l-44.587-40.16a5.374 5.374 0 0 0-7.147 0l-44.586 40.16c-1.813 1.6-.694 4.64 1.76 4.64h9.066v37.334c0 2.933 2.4 5.333 5.334 5.333h16c2.933 0 5.333-2.4 5.333-5.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
