import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryLeadership = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_leadership_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M106.692 32.657a1.702 1.702 0 0 0-2.291.208c-4.258 4.704-14.99 7.998-24.51.104-8.766-7.271-22.203-15.418-37.503-2.21-.37.326-.592.786-.592 1.275l-.207 22.259c0 .742.473 1.41 1.168 1.647.178.06.355.089.532.089.533 0 1.05-.253 1.39-.713.089-.118 9.003-12.301 21.376-6.633 16.453 7.524 25.751 10.343 41.036-13.755a1.718 1.718 0 0 0-.399-2.27Z"
          fill="#0081CC"
        />
        <path
          d="M42.21 71.357c-3.163 0-9.55-1.365-9.55 2.938 0 4.304 2.942 4.304 7.57 4.304"
          fill="#fff"
        />
        <path
          d="M92.708 74.325H61.384c-6.756-5.253-9.875-7.345-12.447-5.906-2.646 1.47-10.614 5.03-9.372 8.073 1.241 3.042 1.685 9.393 4.922 9.437V93.2l2.646 5.98c2.824 0 7.865.727 14.694-3.413h30.925l-.073-21.472.03.03Z"
          fill="#fff"
        />
        <path
          d="M49.735 101.02c-.592 0-1.139-.015-1.626-.029-.4-.015-.754-.03-1.094-.03a1.57 1.57 0 0 1-1.449-.95l-2.676-6.054a1.664 1.664 0 0 1-.133-.653V74.221c0-.875.71-1.602 1.597-1.602h48.531c.872 0 1.597.712 1.597 1.602 0 .89-.71 1.603-1.597 1.603h-46.95v17.154l2.13 4.807h.132c2.853.09 7.155.208 12.787-3.234.252-.149.532-.238.828-.238h31.132c.873 0 1.597.712 1.597 1.603 0 .89-.71 1.602-1.597 1.602H62.256c-5.219 3.087-9.52 3.502-12.521 3.502Z"
          fill="#00253F"
        />
        <path
          d="M40.23 78.925c-3.164 0-8.412-.846-8.412 3.458 0 4.288 6.09 3.457 10.718 3.457"
          fill="#fff"
        />
        <path
          d="M41.368 85.93c-3.164 0-8.412-.847-8.412 3.457 0 4.303 6.091 3.457 10.718 3.457 2.823 0 4.523-1.365 4.523-3.16 0-1.796-.177-3.784-6.386-3.784"
          fill="#fff"
        />
        <path
          d="M43.053 93.052c-3.163 0-8.41-.846-8.41 3.457s4.626 3.458 9.253 3.458c2.823 0 4.523-1.365 4.523-3.161s-.177-3.784-6.386-3.784M42.048 71.224c-3.178 0-9.624-1.38-9.624 2.968 0 4.347 2.957 4.347 7.613 4.347"
          fill="#fff"
        />
        <path
          d="M40.037 80.142c-4.523 0-9.21 0-9.21-5.95 0-1.677.71-2.701 1.316-3.265 1.922-1.796 5.307-1.573 8.027-1.395.695.045 1.33.09 1.863.09.872 0 1.596.711 1.596 1.602 0 .89-.71 1.602-1.596 1.602-.606 0-1.316-.044-2.084-.089-1.893-.118-4.746-.311-5.648.535-.147.133-.31.356-.31.934 0 2.449.68 2.76 6.017 2.76.872 0 1.596.713 1.596 1.603s-.68 1.573-1.567 1.573Z"
          fill="#00253F"
        />
        <path
          d="M40.037 78.866c-3.178 0-8.456-.846-8.456 3.502s6.135 3.502 10.792 3.502"
          fill="#fff"
        />
        <path
          d="M38.264 87.532c-2.765 0-5.367-.267-6.948-1.81C30.444 84.86 30 83.733 30 82.368c0-1.47.458-2.656 1.36-3.532 1.833-1.78 4.893-1.677 7.362-1.587.458.014.916.03 1.315.03.873 0 1.597.711 1.597 1.602 0 .89-.71 1.602-1.597 1.602-.443 0-.916-.014-1.419-.03-1.774-.059-4.183-.133-5.04.698-.163.163-.4.475-.4 1.247 0 .638.192.905.355 1.068 1.035 1.009 4.228.935 6.8.876.695-.015 1.375-.03 2.04-.03.872 0 1.597.712 1.597 1.602 0 .89-.71 1.603-1.597 1.603-.636 0-1.3.015-1.966.03-.71-.03-1.434-.015-2.143-.015Z"
          fill="#00253F"
        />
        <path
          d="M39.416 94.64c-2.764 0-5.366-.267-6.947-1.81-.873-.861-1.316-1.989-1.316-3.354 0-1.47.458-2.656 1.36-3.532 1.833-1.78 4.893-1.677 7.362-1.588.561.015 1.093-.03 1.567.045.074-.015.133-.015.206-.015 5.692 0 8.013 1.573 8.013 5.416 0 2.3-1.922 4.778-6.135 4.778-.636 0-1.301.015-1.966.03-.71.015-1.434.03-2.144.03Zm-1.182-7.138c-1.42 0-2.868.119-3.489.727-.163.164-.4.475-.4 1.247 0 .638.193.905.356 1.068 1.034 1.01 4.227.935 6.8.876.694-.015 1.375-.03 2.04-.03 1.774 0 2.956-.638 2.956-1.588 0-1.187 0-2.21-4.819-2.21-.089 0-.162 0-.251-.016-.488.06-1.05 0-1.626-.014-.503-.03-1.035-.06-1.567-.06Z"
          fill="#00253F"
        />
        <path
          d="M39.919 101.822c-2.306 0-4.376-.238-5.75-1.573-.887-.876-1.346-2.078-1.346-3.591 0-1.47.459-2.657 1.36-3.532 1.833-1.78 4.908-1.677 7.362-1.588.606.015 1.183-.044 1.685.075 4.686.252 6.623 1.854 6.623 5.371 0 2.3-1.922 4.778-6.135 4.778-.65 0-1.3.015-1.937.03-.62.015-1.256.03-1.862.03Zm-.015-7.138c-1.419 0-2.868.119-3.489.727-.162.163-.399.475-.399 1.247 0 .934.281 1.202.385 1.305.768.757 3.178.698 5.307.639.665-.015 1.345-.03 2.025-.03 1.774 0 2.956-.638 2.956-1.588 0-1.187 0-2.21-4.819-2.21-.118 0-.236-.016-.34-.03h-.059a17.985 17.985 0 0 0-1.567-.06Z"
          fill="#00253F"
        />
        <path
          d="M92.397 74.503H61.723c-7.08-5.52-10.259-7.746-12.905-6.262-2.646 1.484-10.688 5.09-9.417 8.177 1.272 3.071 1.686 9.556 4.967 9.556 3.282 0 4.76-6.055 4.76-6.055.532 3.295 6.342 4.986 8.042 5.313"
          fill="#fff"
        />
        <path
          d="M44.383 87.576c-3.607 0-4.7-4.229-5.573-7.642-.28-1.113-.562-2.151-.857-2.908-1.641-3.992 4.612-7.286 8.766-9.467.532-.282.99-.52 1.345-.713 3.666-2.062 7.672.965 14.221 6.055h30.127c.872 0 1.597.712 1.597 1.602 0 .89-.71 1.603-1.597 1.603H61.738c-.355 0-.695-.119-.975-.341-6.431-5.016-9.432-7.094-11.147-6.129-.384.208-.872.475-1.419.757-7.066 3.71-7.45 5.015-7.288 5.401.4.95.695 2.107 1.006 3.324.532 2.092 1.36 5.238 2.498 5.238 1.434 0 2.794-3.146 3.208-4.837a1.591 1.591 0 0 1 3.119.118c.31 1.96 4.627 3.591 6.755 3.992a1.603 1.603 0 0 1 1.272 1.87c-.163.86-.99 1.44-1.863 1.276-.059-.015-4.627-.905-7.362-3.339-1.005 2.003-2.646 4.14-5.159 4.14Z"
          fill="#00253F"
        />
        <path
          d="M45.935 20h-5.913v50.378h5.913V20ZM45.935 100.991h-5.913v7.805h5.913v-7.805Z"
          fill="#991E23"
        />
        <path
          d="M45.935 20h-5.913v50.378h5.913V20ZM45.935 100.991h-5.913v7.805h5.913v-7.805Z"
          fill="#00253F"
        />
      </g>
      <defs>
        <clipPath id="icon_category_leadership_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
