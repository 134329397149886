interface Props {
  width?: number
  height?: number
}

export const Pportrait: React.FC<Props> = ({ width = 30, height = 30 }) => {
  return (
    <img
      src={`${process.env.IMG_SERVER}/icon/portrait.png`}
      width={width}
      height={height}
    />
  )
}
