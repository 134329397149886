interface Props {
  color?: string
}

export const Nice: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.60019 16.2H13.8002C14.4642 16.2 15.0322 15.8 15.2722 15.224L17.6882 9.58395C17.7602 9.39995 17.8002 9.20795 17.8002 8.99995V7.39995C17.8002 6.51995 17.0802 5.79995 16.2002 5.79995H11.1522L11.9122 2.14395L11.9362 1.88795C11.9362 1.55995 11.8002 1.25595 11.5842 1.03995L10.7362 0.199951L5.46419 5.47195C5.1762 5.75995 5.0002 6.15995 5.0002 6.59995V14.6C5.0002 15.48 5.72019 16.2 6.60019 16.2ZM6.60019 6.59995L10.0722 3.12795L9.00019 7.39995H16.2002V8.99995L13.8002 14.6H6.60019V6.59995ZM0.200195 6.59995H3.4002V16.2H0.200195V6.59995Z"
        fill={color || '#666666'}
      />
    </svg>
  )
}
