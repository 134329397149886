import * as React from 'react'
import { SVGProps } from 'react'
export const IconTabbarSearch = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55 87c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32Zm0 12c24.3 0 44-19.7 44-44S79.3 11 55 11 11 30.7 11 55s19.7 44 44 44Z"
        fill="currentColor"
      />
      <path
        d="m78 88 9.9-9.9 25.455 25.456a4 4 0 0 1 0 5.657l-4.242 4.243a4 4 0 0 1-5.657 0L78 88ZM75 55c1.105 0 2.01-.897 1.91-1.997a22 22 0 0 0-35.99-14.907c-.848.707-.843 1.98-.062 2.762l4.016 4.016c.781.781 2.04.765 2.937.121a12.32 12.32 0 0 1 19.347 8.014C67.337 54.099 68.216 55 69.32 55H75Z"
        fill="currentColor"
      />
    </svg>
  )
}
