import * as React from 'react'
import { SVGProps } from 'react'
export const IconStars = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M63.947 10.666c-29.44 0-53.28 23.894-53.28 53.334 0 29.44 23.84 53.333 53.28 53.333 29.493 0 53.387-23.893 53.387-53.333 0-29.44-23.894-53.334-53.387-53.334Zm17.227 82.08L64 82.4 46.827 92.746c-2.027 1.227-4.533-.586-4-2.88l4.533-19.52L32.267 57.28c-1.76-1.547-.8-4.48 1.547-4.694L53.76 50.88l7.787-18.4c.907-2.187 4-2.187 4.907 0l7.786 18.347 19.947 1.706c2.347.213 3.307 3.147 1.493 4.694L80.587 70.293l4.533 19.573c.534 2.294-1.92 4.107-3.946 2.88Z"
        fill="currentColor"
      />
    </svg>
  )
}
