import * as React from 'react'
import { SVGProps } from 'react'
export const IconIndex = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.333 16h85.334C109.6 16 112 18.4 112 21.333v85.334c0 2.933-2.4 5.333-5.333 5.333H21.333C18.4 112 16 109.6 16 106.667V21.333C16 18.4 18.4 16 21.333 16ZM32 39a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm0 25a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm6 19a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm18-44a4 4 0 0 1 4-4h32a4 4 0 0 1 0 8H60a4 4 0 0 1-4-4Zm4 21a4 4 0 0 0 0 8h32a4 4 0 0 0 0-8H60Zm-4 29a4 4 0 0 1 4-4h32a4 4 0 0 1 0 8H60a4 4 0 0 1-4-4Z"
        fill="currentColor"
      />
    </svg>
  )
}
