import * as React from 'react'
import { SVGProps } from 'react'
export const IconBookmark = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M90.667 16H37.334c-5.867 0-10.614 4.8-10.614 10.667L26.667 112 64 96l37.334 16V26.667c0-5.867-4.8-10.667-10.667-10.667Z"
        fill="currentColor"
      />
    </svg>
  )
}
