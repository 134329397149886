import * as React from 'react'
import { SVGProps } from 'react'
export const Icon15SForward = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M65.63 39.042V18.627c0-2.452 2.606-4.026 4.777-2.884L90.82 26.488c2.377 1.25 2.305 4.68-.123 5.83l-20.414 9.67c-2.162 1.024-4.655-.554-4.655-2.946ZM50.104 83.303h5.768V55.746h-5.749L43 60.692v5.194l6.76-4.698h.344v22.115ZM73.823 84.01c6.188 0 10.465-3.954 10.465-9.76v-.037c0-5.252-3.704-9.033-8.842-9.033-2.635 0-4.774 1.145-5.882 2.998h-.343l.687-7.849h12.738v-4.583H65.42l-1.28 16.309h4.947c.305-.592.764-1.108 1.28-1.528.916-.783 2.1-1.184 3.494-1.184 2.922 0 5.004 2.043 5.004 4.984v.038c0 2.96-2.063 5.042-5.023 5.042-2.597 0-4.488-1.623-4.889-3.647l-.038-.134h-5.233l.02.267c.324 4.603 4.22 8.117 10.121 8.117Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.62 33.603a35.852 35.852 0 1 0 25.704 21.81 4.074 4.074 0 0 1 7.573-3.006 43.995 43.995 0 0 1-15.242 51.977 44.006 44.006 0 0 1-68.741-26.826A44 44 0 0 1 73.35 25.64a4.074 4.074 0 1 1-1.732 7.962Z"
        fill="currentColor"
      />
    </svg>
  )
}
