import * as React from 'react'
import { SVGProps } from 'react'
export const IconPictureAsPdf = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M106.667 10.666h-64C36.8 10.666 32 15.466 32 21.334v64C32 91.2 36.8 96 42.667 96h64c5.867 0 10.667-4.8 10.667-10.667v-64c0-5.866-4.8-10.666-10.667-10.666Zm-45.333 40c0 4.427-3.574 8-8 8H48v6.667c0 2.187-1.813 4-4 4-2.186 0-4-1.813-4-4V42.667c0-2.934 2.4-5.334 5.334-5.334h8c4.426 0 8 3.574 8 8v5.334ZM88 61.334c0 4.427-3.573 8-8 8H69.334a2.64 2.64 0 0 1-2.667-2.666V40a2.64 2.64 0 0 1 2.667-2.667H80c4.427 0 8 3.574 8 8v16Zm21.334-20c0 2.187-1.814 4-4 4h-4v5.334h4c2.186 0 4 1.813 4 4 0 2.186-1.814 4-4 4h-4v6.666c0 2.187-1.814 4-4 4-2.187 0-4-1.813-4-4V42.667c0-2.934 2.4-5.334 5.333-5.334h6.667c2.186 0 4 1.814 4 4ZM48 50.667h5.334v-5.334H48v5.334ZM16 32c-2.933 0-5.333 2.4-5.333 5.333v69.334c0 5.866 4.8 10.666 10.667 10.666h69.333c2.933 0 5.333-2.4 5.333-5.333 0-2.933-2.4-5.333-5.333-5.333h-64c-2.933 0-5.333-2.4-5.333-5.334v-64C21.334 34.4 18.934 32 16 32Zm58.667 29.333H80v-16h-5.333v16Z"
        fill="currentColor"
      />
    </svg>
  )
}
