interface Props {
  width?: number
  height?: number
}

export const BizEdit: React.FC<Props> = ({ width = 25, height = 25 }) => {
  return (
    <img
      className="my-[10px]"
      src={`${process.env.IMG_SERVER}/biz-groups/icon_edit.svg`}
      alt="みんなのメモ"
      width={width}
      height={height}
    />
  )
}
