import * as React from 'react'
import { SVGProps } from 'react'
export const IconContentPasteSearch = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.433 27.727H33.1v5.333c0 5.867 4.8 10.667 10.667 10.667h32c5.866 0 10.666-4.8 10.666-10.667v-5.333H97.1v26.667h10.667V27.727c0-5.867-4.8-10.667-10.667-10.667H74.807c-2.24-6.186-8.107-10.666-15.04-10.666-6.934 0-12.8 4.48-15.04 10.666H22.433c-5.866 0-10.666 4.8-10.666 10.667v74.667c0 5.866 4.8 10.666 10.666 10.666H49.1v-10.666H22.433V27.727ZM59.767 17.06c2.933 0 5.333 2.4 5.333 5.334 0 2.933-2.4 5.333-5.333 5.333-2.934 0-5.334-2.4-5.334-5.333 0-2.934 2.4-5.334 5.334-5.334Z"
        fill="currentColor"
      />
      <path
        d="m114.7 112.527-10.667-10.667c3.094-5.386 5.067-11.893 2.72-19.466-2.826-9.174-10.88-16.267-20.48-17.174-15.306-1.493-27.893 11.04-26.4 26.4.96 9.547 8 17.654 17.174 20.48 7.626 2.347 14.08.374 19.466-2.72l10.667 10.667a5.248 5.248 0 0 0 7.467 0c2.08-2.08 2.133-5.44.053-7.52Zm-30.933-10.133c-7.467 0-13.334-5.867-13.334-13.334 0-7.466 5.867-13.333 13.334-13.333 7.466 0 13.333 5.867 13.333 13.333 0 7.467-5.867 13.334-13.333 13.334Z"
        fill="currentColor"
      />
    </svg>
  )
}
