interface Props {
  width?: number
  height?: number
}

export const Play: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 27 39`}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        opacity="0.75"
        d="M24.9731 17.2143L3.95006 0.352326C3.62926 0.121397 3.26541 -0.000121665 2.89507 9.14057e-08C2.52473 0.000121848 2.16094 0.121873 1.84024 0.353012C1.51953 0.584151 1.25319 0.91654 1.06799 1.3168C0.882795 1.71707 0.785253 2.17111 0.785156 2.63332V36.3573C0.785172 36.8197 0.882704 37.2739 1.06794 37.6743C1.25318 38.0747 1.5196 38.4072 1.84043 38.6383C2.16126 38.8695 2.5252 38.9912 2.89566 38.9912C3.26612 38.9912 3.63004 38.8695 3.95087 38.6383L24.9739 21.7763C25.2946 21.545 25.561 21.2125 25.7461 20.812C25.9312 20.4116 26.0287 19.9574 26.0286 19.495C26.0285 19.0327 25.9309 18.5786 25.7456 18.1782C25.5604 17.7779 25.2939 17.4454 24.9731 17.2143Z"
        fill="white"
      />
    </svg>
  )
}
