import * as React from 'react'
import { SVGProps } from 'react'
export const IconTextSnippet = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M75.573 16H26.667C20.8 16 16 20.8 16 26.667v74.666C16 107.2 20.8 112 26.667 112h74.666C107.2 112 112 107.2 112 101.333V52.427c0-2.827-1.12-5.547-3.147-7.52l-25.76-25.76C81.12 17.12 78.4 16 75.573 16ZM42.667 80h42.666c2.934 0 5.334 2.4 5.334 5.333 0 2.934-2.4 5.334-5.334 5.334H42.667c-2.934 0-5.334-2.4-5.334-5.334 0-2.933 2.4-5.333 5.334-5.333Zm0-21.333h42.666c2.934 0 5.334 2.4 5.334 5.333 0 2.933-2.4 5.333-5.334 5.333H42.667c-2.934 0-5.334-2.4-5.334-5.333 0-2.933 2.4-5.333 5.334-5.333Zm0-21.334h26.666c2.934 0 5.334 2.4 5.334 5.334 0 2.933-2.4 5.333-5.334 5.333H42.667c-2.934 0-5.334-2.4-5.334-5.333 0-2.934 2.4-5.334 5.334-5.334Z"
        fill="currentColor"
      />
    </svg>
  )
}
