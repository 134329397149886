import * as React from 'react'
import { SVGProps } from 'react'
export const IconTabbarHome = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.928 112a4.017 4.017 0 0 0 4.016-4.017V80h22.088v27.983A4.017 4.017 0 0 0 79.048 112h21.578c2.218 0 3.374-1.799 3.374-4.017V64h9.988c1.84 0 2.711-2.768 1.343-4L67.688 17.409a5.563 5.563 0 0 0-7.4 0L12.669 60c-1.367 1.232-.496 4 1.344 4H24v43.983c0 2.218 1.133 4.017 3.35 4.017h21.578Z"
        fill="currentColor"
      />
    </svg>
  )
}
