interface Props {
  width?: number
  height?: number
}

export const PportraitFill: React.FC<Props> = ({ width = 30, height = 30 }) => {
  return (
    <img
      src={`${process.env.IMG_SERVER}/icon/portrait-1.png`}
      width={width}
      height={height}
    />
  )
}
