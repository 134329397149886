interface Props {
  className?: string
}
export const MenuCategory: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.00004 9.16663L9.58337 1.66663L14.1667 9.16663H5.00004Z"
        fill="#1A6DCC"
      />
      <path
        d="M10.4167 14.5833C10.4167 12.5083 12.0917 10.8333 14.1667 10.8333C16.2417 10.8333 17.9167 12.5083 17.9167 14.5833C17.9167 16.6583 16.2417 18.3333 14.1667 18.3333C12.0917 18.3333 10.4167 16.6583 10.4167 14.5833Z"
        fill="#1A6DCC"
      />
      <path
        d="M8.75004 17.9166H2.08337V11.25H8.75004V17.9166Z"
        fill="#1A6DCC"
      />
    </svg>
  )
}
