import * as React from 'react'
import { SVGProps } from 'react'
export const OpenInNew = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="#1F2C5C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M96 101.333H32c-2.933 0-5.333-2.4-5.333-5.333V32c0-2.933 2.4-5.333 5.333-5.333h26.667c2.933 0 5.333-2.4 5.333-5.334C64 18.4 61.6 16 58.667 16h-32C20.747 16 16 20.8 16 26.667v74.666C16 107.2 20.8 112 26.667 112h74.666C107.2 112 112 107.2 112 101.333v-32C112 66.4 109.6 64 106.667 64c-2.934 0-5.334 2.4-5.334 5.333V96c0 2.933-2.4 5.333-5.333 5.333Zm-21.333-80c0 2.934 2.4 5.334 5.333 5.334h13.813L45.12 75.36a5.311 5.311 0 0 0 0 7.52 5.311 5.311 0 0 0 7.52 0l48.693-48.693V48c0 2.933 2.4 5.333 5.334 5.333 2.933 0 5.333-2.4 5.333-5.333V21.333C112 18.4 109.6 16 106.667 16H80c-2.933 0-5.333 2.4-5.333 5.333Z" />
    </svg>
  )
}
