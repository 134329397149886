import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryHr = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_hr_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M40.346 96.798c-4.849 0-6.12-1.78-4.095-7.271 2.218-6.04 4.642-12.302 8.101-17.228 11.915-17.154 32.241-15.284 42.53 3.65 2.38 4.363 4.124 9.408 5.795 14.335 1.463 4.362.354 6.232-3.341 6.276-8.264.045-40.933.282-48.99.282v-.044Z"
          fill="#0081CC"
        />
        <path
          d="M77.229 44.699c0 7.107-5.677 12.82-12.787 12.865-7.229 0-13.068-5.713-13.068-12.865 0-7.227 5.75-13.014 12.905-13.014 7.155 0 12.906 5.802 12.906 13.014h.044Z"
          fill="#0081CC"
        />
        <path
          d="M23.036 54.092c0-5.357 4.405-9.601 9.89-9.601 5.277 0 9.564 4.451 9.534 9.808 0 5.283-4.405 9.69-9.683 9.69-5.44 0-9.771-4.363-9.727-9.838v-.06h-.014ZM41.972 69.569c-.192.192-5.677 8.176-7.273 13.533H17.95c-1.833 0-2.853-.876-2.261-2.657C19.222 70 28.594 63.93 39.519 68.174c.265.118 2.143 1.157 2.453 1.394ZM105.774 54.092c0-5.357-4.406-9.601-9.89-9.601-5.277 0-9.564 4.451-9.535 9.808 0 5.283 4.405 9.69 9.683 9.69 5.44 0 9.771-4.363 9.727-9.838v-.06h.015ZM86.882 69.569c.192.192 5.676 8.176 7.273 13.533H110.904c1.833 0 2.853-.876 2.261-2.657C109.632 70 100.26 63.93 89.335 68.174c-.265.118-2.143 1.157-2.453 1.394Z"
          fill="#00253F"
        />
      </g>
      <defs>
        <clipPath id="icon_category_hr_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
