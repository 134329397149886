interface Props {
  width?: number
  height?: number
}

export const GoldBadge: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width || 19}
      height={height || 24}
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2879_112229"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="24"
      >
        <rect width="19" height="24" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_2879_112229)">
        <path
          d="M5.25732 23.9998V16.1865H13.852V23.9998L9.55465 21.6559L5.25732 23.9998Z"
          fill="#EE3628"
        />
        <circle cx="9.55462" cy="9.15423" r="8.59466" fill="#B3B3B3" />
        <circle
          cx="9.55458"
          cy="9.15479"
          r="8.59466"
          transform="rotate(-45 9.55458 9.15479)"
          fill="#F0D051"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.47705 15.2314L15.6312 3.07725L15.7088 3.15479C18.9877 6.51702 18.9619 11.9007 15.6314 15.2311C12.2751 18.5875 6.8335 18.5876 3.47705 15.2314Z"
          fill="#E9BD35"
        />
      </g>
    </svg>
  )
}
