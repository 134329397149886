import * as React from 'react'
import { SVGProps } from 'react'
export const IconArrowDouble = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.359 23.028c1.904-2.153 5.101-2.27 7.141-.26L62.395 60.1c1.024 1.009 1.605 2.42 1.605 3.9 0 1.477-.581 2.89-1.605 3.898L24.5 105.232c-2.04 2.01-5.237 1.894-7.141-.259-1.904-2.154-1.794-5.529.246-7.538L51.542 64 17.605 30.566c-2.04-2.01-2.15-5.385-.246-7.538Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.359 23.028c1.904-2.153 5.101-2.27 7.141-.26L110.395 60.1c1.024 1.009 1.605 2.42 1.605 3.9 0 1.477-.581 2.89-1.605 3.898L72.5 105.232c-2.04 2.01-5.237 1.894-7.141-.259-1.904-2.154-1.794-5.529.246-7.538L99.542 64 65.605 30.566c-2.04-2.01-2.15-5.385-.246-7.538Z"
        fill="currentColor"
      />
    </svg>
  )
}
