import * as React from 'react'
import { SVGProps } from 'react'
export const IconLock = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M96 45.333h-5.333V34.667C90.667 19.947 78.72 8 64 8 49.28 8 37.334 19.947 37.334 34.667v10.666H32c-5.866 0-10.666 4.8-10.666 10.667v53.333C21.334 115.2 26.134 120 32 120h64c5.867 0 10.667-4.8 10.667-10.667V56c0-5.867-4.8-10.667-10.667-10.667Zm-32 48c-5.867 0-10.666-4.8-10.666-10.666C53.334 76.8 58.133 72 64 72c5.867 0 10.667 4.8 10.667 10.667 0 5.866-4.8 10.666-10.667 10.666Zm-16-48V34.667c0-8.854 7.147-16 16-16s16 7.146 16 16v10.666H48Z"
        fill="currentColor"
      />
    </svg>
  )
}
