import * as React from 'react'
import { SVGProps } from 'react'
export const IconTabbarMypage = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 60c13.227 0 24-10.773 24-24S77.227 12 64 12 40 22.773 40 36s10.773 24 24 24Zm-1 8c-22.091 0-40 17.909-40 40v4a4 4 0 0 0 4 4h74a4 4 0 0 0 4-4v-4c0-22.091-17.909-40-40-40h-2Z"
        fill="currentColor"
      />
    </svg>
  )
}
