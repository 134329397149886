interface Props {
  width?: number
  height?: number
}

export const RecordBlue: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width || 19}
      height={height || 19}
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.083 0.750033C12.4622 0.676688 10.8592 1.1139 9.50001 2.00003C8.14057 1.1137 6.5372 0.676476 4.91601 0.750033C3.29516 0.676688 1.69216 1.1139 0.333008 2.00003V14.208C0.333008 14.75 0.941008 14.583 0.958008 14.583C2.20224 14.0143 3.54843 13.7024 4.91601 13.666C6.53686 13.5927 8.13985 14.0299 9.49901 14.916C10.9047 14.1387 12.4763 13.71 14.082 13.666C15.4527 13.6335 16.8108 13.9338 18.04 14.541C18.1052 14.5714 18.1769 14.5853 18.2487 14.5815C18.3205 14.5777 18.3904 14.5564 18.452 14.5194C18.5137 14.4824 18.5654 14.4308 18.6025 14.3692C18.6396 14.3076 18.6611 14.2379 18.665 14.166V2.00003C17.3039 1.11909 15.7029 0.682328 14.083 0.750033ZM17 12.417C16.0546 12.13 15.0709 11.9894 14.083 12C12.4773 12.044 10.9057 12.4727 9.50001 13.25V3.66703C10.9057 2.88968 12.4773 2.46101 14.083 2.41703C15.0709 2.40642 16.0546 2.54705 17 2.83403V12.417Z"
        fill="#1A6DCC"
      />
    </svg>
  )
}
