import * as React from 'react'
import { SVGProps } from 'react'
export const IconSummaryRecommend = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.8 22 38.043 63.245V99.5s0 12.384 26.947 12.384l26.48.117v-.003a7.464 7.464 0 0 0 .084-14.927h4.466v-.003a7.464 7.464 0 0 0 0-14.926l3.966-.001v-.003h.115a7.464 7.464 0 0 0 .931-14.87l.004-.057h3.499v-.002a7.464 7.464 0 1 0-.001-14.928H73.923a.84.84 0 0 1-.808-1.072l6.685-23.21L60.8 22Z"
        fill="currentColor"
      />
      <path
        d="M80.001 26c0 5.522-4.477 10-10 10s-10-4.478-10-10 4.477-9.999 10-9.999 10 4.477 10 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.946 60.226v43.829h13.738v-43.83H23.946Zm-.48-7.945A7.465 7.465 0 0 0 16 59.746v44.789A7.465 7.465 0 0 0 23.466 112h14.698a7.465 7.465 0 0 0 7.466-7.465v-44.79a7.465 7.465 0 0 0-7.466-7.464H23.466Z"
        fill="currentColor"
      />
    </svg>
  )
}
