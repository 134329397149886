import * as React from 'react'
import { SVGProps } from 'react'
export const IconClipDark1 = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.454 3.829a.378.378 0 0 0-.105.07l-9.135 9.138a4.83 4.83 0 0 0-.248 6.682 4.733 4.733 0 0 0 3.42 1.553h.118a4.728 4.728 0 0 0 3.37-1.397l9.227-9.227a.264.264 0 0 0 .063-.102 4.773 4.773 0 0 0-6.71-6.717Zm5.142 5.625-6.472 6.472-.035.032a1.451 1.451 0 0 1-1.877.092 1.402 1.402 0 0 1-.153-2.033l5.352-5.358a.955.955 0 0 0-1.352-1.35l-5.41 5.41a.254.254 0 0 0-.047.067 3.306 3.306 0 0 0 .15 4.521c.343.344.758.61 1.215.777l-.366.366a2.93 2.93 0 0 1-3.965.21 2.826 2.826 0 0 1-.98-2.062 2.863 2.863 0 0 1 .834-2.123l9.036-9.036.118-.102a2.863 2.863 0 0 1 2.214-.674 2.835 2.835 0 0 1 1.995 1.18 2.946 2.946 0 0 1-.257 3.611Z"
        fill="currentColor"
      />
    </svg>
  )
}
