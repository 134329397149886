import * as React from 'react'
import { SVGProps } from 'react'
export const IconVolumeOff = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.36 19.387a5.311 5.311 0 0 0 0 7.52l19.52 19.52-1.547 1.6h-16c-2.933 0-5.333 2.4-5.333 5.333v21.333c0 2.934 2.4 5.334 5.333 5.334h16L54.88 97.573c3.36 3.36 9.12.96 9.12-3.786v-22.24L86.293 93.84c-2.613 1.973-5.44 3.627-8.533 4.853-1.92.8-3.093 2.827-3.093 4.907 0 3.84 3.893 6.293 7.413 4.853a48.081 48.081 0 0 0 11.84-6.986l7.147 7.146a5.311 5.311 0 0 0 7.52 0 5.311 5.311 0 0 0 0-7.52L26.933 19.387c-2.08-2.08-5.44-2.08-7.573 0Zm81.973 44.64c0 4.373-.8 8.586-2.186 12.48l8.16 8.16A47.666 47.666 0 0 0 112 64.027c0-20.427-12.8-37.92-30.827-44.8-3.146-1.227-6.506 1.226-6.506 4.586v1.014c0 2.026 1.333 3.786 3.253 4.533 13.707 5.547 23.413 18.987 23.413 34.667ZM54.88 30.48l-.907.907L64 41.413v-7.2c0-4.746-5.76-7.093-9.12-3.733ZM88 64.027c0-9.44-5.44-17.547-13.333-21.494v9.547l13.226 13.227c.054-.427.107-.854.107-1.28Z"
        fill="currentColor"
      />
    </svg>
  )
}
