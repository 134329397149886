import * as React from 'react'
import { SVGProps } from 'react'
export const IconNotifications = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.006 116c5.867 0 10.667-4.8 10.667-10.667H53.339C53.34 111.2 58.087 116 64.007 116Zm32-32V57.333c0-16.373-8.746-30.08-24-33.706V20c0-4.427-3.573-8-8-8a7.99 7.99 0 0 0-8 8v3.627c-15.306 3.626-24 17.28-24 33.706V84l-6.88 6.88c-3.36 3.36-1.013 9.12 3.733 9.12H99.1c4.747 0 7.147-5.76 3.787-9.12L96.006 84Z"
        fill="currentColor"
      />
    </svg>
  )
}
