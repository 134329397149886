import * as React from 'react'
import { SVGProps } from 'react'
export const IconFavorite = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M71.199 108.305c-4.053 3.68-10.293 3.68-14.347-.053l-.586-.534c-28-25.333-46.294-41.92-45.6-62.613.32-9.067 4.96-17.76 12.48-22.88 14.08-9.6 31.466-5.12 40.853 5.867 9.386-10.987 26.773-15.52 40.853-5.867 7.52 5.12 12.16 13.813 12.48 22.88.747 20.693-17.6 37.28-45.6 62.72l-.533.48Z"
        fill="currentColor"
      />
    </svg>
  )
}
