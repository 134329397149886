import * as React from 'react'
import { SVGProps } from 'react'
export const IconNaruhodo = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 9.5C4.5 5.36 7.86 2 12 2c4.14 0 7.5 3.36 7.5 7.5 0 3.47-2.195 5.471-3.43 6.289-.219.145-.479.21-.742.21H8.671c-.263 0-.523-.065-.742-.21-1.234-.818-3.43-2.82-3.43-6.29Zm3.75 9.125c0 .207.168.375.375.375h6.73a.375.375 0 0 0 .375-.375v-1.25a.375.375 0 0 0-.375-.375h-6.73a.375.375 0 0 0-.375.375v1.25ZM12 22c1.037 0 1.896-.8 1.991-1.813a.175.175 0 0 0-.179-.187h-3.625a.175.175 0 0 0-.178.187A2.007 2.007 0 0 0 11.999 22ZM6.387 9.187a.357.357 0 0 0 .363.375h1.125a.406.406 0 0 0 .393-.374 3.753 3.753 0 0 1 3.357-3.357.406.406 0 0 0 .375-.394V4.312a.357.357 0 0 0-.375-.362 5.628 5.628 0 0 0-5.238 5.237Z"
        fill="currentColor"
      />
    </svg>
  )
}
