import * as React from 'react'
import { SVGProps } from 'react'
export const Slideshow = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.333 12.053v7.894c0 .56.64.866 1.08.52l4.934-3.947a.666.666 0 0 0 0-1.04l-4.934-3.947a.664.664 0 0 0-1.08.52Zm12-8.053H6.667A2.675 2.675 0 0 0 4 6.667v18.666C4 26.8 5.2 28 6.667 28h18.666C26.8 28 28 26.8 28 25.333V6.667C28 5.2 26.8 4 25.333 4ZM24 25.333H8c-.733 0-1.333-.6-1.333-1.333V8c0-.733.6-1.333 1.333-1.333h16c.733 0 1.333.6 1.333 1.333v16c0 .733-.6 1.333-1.333 1.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
