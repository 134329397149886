import * as React from 'react'
import { SVGProps } from 'react'
export const Twitter = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12.5} cy={12.5} r={12.5} fill="#48AEEA" />
      <path
        d="M19.05 10.018c.008.133.008.266.008.4 0 4.089-3.072 8.804-8.69 8.804v-.002a8.566 8.566 0 0 1-4.682-1.387 6.083 6.083 0 0 0 4.52-1.282 3.063 3.063 0 0 1-2.854-2.15c.458.09.93.071 1.38-.053-1.425-.292-2.45-1.56-2.45-3.033v-.04c.424.24.9.373 1.386.388a3.123 3.123 0 0 1-.946-4.132 8.632 8.632 0 0 0 6.295 3.233 3.122 3.122 0 0 1 .884-2.956 3.03 3.03 0 0 1 4.322.134 6.081 6.081 0 0 0 1.94-.751 3.1 3.1 0 0 1-1.343 1.711 6.012 6.012 0 0 0 1.754-.487 6.251 6.251 0 0 1-1.525 1.603Z"
        fill="#fff"
      />
    </svg>
  )
}
