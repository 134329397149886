interface Props {
  className?: string
  size?: number
}
export const CheckCircle: React.FC<Props> = ({ className, size }) => {
  const s = size || 20
  return (
    <span className={`checkmark ${className || ''}`}>
      <style jsx>{`
        .checkmark {
          padding-left: ${s * 1.2}px;
          position: relative;
        }

        .checkmark:before,
        .checkmark:after {
          content: '';
          display: block;
          position: absolute;
        }
        .checkmark:before {
          width: ${s}px;
          height: ${s}px;
          border-radius: ${s / 2}px;
          background: #1a6dcc;
          left: 0;
          top: ${s / 10}px;
        }
        .checkmark:after {
          border-left: ${Math.floor(s / 6)}px solid #fff;
          border-bottom: ${Math.floor(s / 6)}px solid #fff;
          width: ${s / 2}px;
          height: ${s / 4}px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          left: ${s / 4}px;
          top: ${s * 0.45}px;
        }
      `}</style>
    </span>
  )
}
