import { SVGProps } from 'react'

export const IconTriangle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path d="M6 12L0 0H12L6 12Z" fill="url(#paint0_linear_498_1023)" />
      <defs>
        <linearGradient
          id="paint0_linear_498_1023"
          x1="15.0464"
          y1="-4.26157"
          x2="0.777409"
          y2="12.6688"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.20622" stopColor="#408BED" />
          <stop offset="1" stopColor="#044DAB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
