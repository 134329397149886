import * as React from 'react'
import { SVGProps } from 'react'
export const IconTabbarRanking = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M116 30.796a4.129 4.129 0 0 0-4.129-4.13H94.667V16.13A4.13 4.13 0 0 0 90.538 12H37.463a4.13 4.13 0 0 0-4.13 4.129v10.538H16.13A4.129 4.129 0 0 0 12 30.796v11.87c0 13.6 10.24 24.694 23.414 26.347C35.414 69.013 42 86 58 90h12c16-4 22.587-20.987 22.587-20.987C105.76 67.36 116 56.267 116 42.667V30.796Zm-93.333 11.87v-5.333h10.667v20.374c-6.187-2.24-10.667-8.107-10.667-15.04Zm82.667 0c0 6.934-4.48 12.8-10.667 15.04V37.334h10.667v5.334Z"
        fill="currentColor"
      />
      <path d="M57 76h14v40H57V76Z" fill="currentColor" />
      <path
        d="M84 104a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H44a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h40Z"
        fill="currentColor"
      />
    </svg>
  )
}
