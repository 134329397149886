import * as React from 'react'
import { SVGProps } from 'react'
export const IconFavoriteLine = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104.857 22.224c-14.08-9.6-31.467-5.12-40.853 5.867-9.387-10.987-26.774-15.52-40.854-5.867-7.466 5.12-12.16 13.76-12.48 22.88-.746 20.694 17.6 37.28 45.6 62.72l.534.48c4.053 3.68 10.293 3.68 14.346-.053l.587-.533c28-25.387 46.293-41.974 45.6-62.667-.32-9.067-5.013-17.707-12.48-22.827Zm-40.32 77.654-.533.533-.534-.533C38.084 76.89 21.337 61.69 21.337 46.278c0-10.667 8-18.667 18.667-18.667 8.213 0 16.213 5.28 19.04 12.587h9.973C71.79 32.89 79.79 27.61 88.004 27.61c10.666 0 18.666 8 18.666 18.667 0 15.413-16.746 30.613-42.133 53.6Z"
        fill="currentColor"
      />
    </svg>
  )
}
