import * as React from 'react'
import { SVGProps } from 'react'
export const IconMenu = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.333 96h85.334C109.6 96 112 93.6 112 90.667c0-2.934-2.4-5.334-5.333-5.334H21.333c-2.933 0-5.333 2.4-5.333 5.334C16 93.6 18.4 96 21.333 96Zm0-26.667h85.334c2.933 0 5.333-2.4 5.333-5.333 0-2.933-2.4-5.333-5.333-5.333H21.333C18.4 58.667 16 61.067 16 64c0 2.933 2.4 5.333 5.333 5.333Zm-5.333-32c0 2.934 2.4 5.334 5.333 5.334h85.334c2.933 0 5.333-2.4 5.333-5.334C112 34.4 109.6 32 106.667 32H21.333C18.4 32 16 34.4 16 37.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
