import * as React from 'react'
import { SVGProps } from 'react'
export const IconImportContacts = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M93.36 19.613c-10.4 0-21.6 2.134-29.334 8-7.733-5.866-18.933-8-29.333-8-7.733 0-15.946 1.174-22.826 4.214-3.894 1.76-6.507 5.546-6.507 9.866v60.16c0 6.934 6.506 12.054 13.226 10.347 5.227-1.333 10.774-1.92 16.107-1.92 8.32 0 17.174 1.387 24.32 4.907 3.2 1.6 6.827 1.6 9.974 0 7.146-3.574 16-4.907 24.32-4.907 5.333 0 10.88.587 16.106 1.92 6.72 1.76 13.227-3.36 13.227-10.347v-60.16c0-4.32-2.613-8.106-6.507-9.866-6.826-3.04-15.04-4.214-22.773-4.214Zm18.667 67.894c0 3.36-3.094 5.813-6.4 5.226-4-.746-8.16-1.066-12.267-1.066-9.067 0-22.133 3.466-29.334 8V38.28c7.2-4.533 20.267-8 29.334-8 4.906 0 9.76.48 14.4 1.493 2.453.534 4.267 2.72 4.267 5.227v50.507Z"
        fill="currentColor"
      />
    </svg>
  )
}
