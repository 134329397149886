import css from 'styled-jsx/css'
const styles = css`
  .loading {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

interface Props {
  className?: string
}
export const Loading: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`loading ${className} || ''`}
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36ZM20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#1A6DCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 20H40C40 8.9543 31.0457 0 20 0V4C28.8366 4 36 11.1634 36 20Z"
        fill="#1A6DCC"
      />
      <style jsx>{styles}</style>
    </svg>
  )
}
