import * as React from 'react'
import { SVGProps } from 'react'
export const IconTabbarFeature = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108 16a4 4 0 0 1 4 4v88a4 4 0 0 1-4 4H20a4 4 0 0 1-4-4V20a4 4 0 0 1 4-4h88ZM96 34.182c.001-1.205-.922-2.182-2.063-2.182H34.063c-1.14 0-2.063.976-2.063 2.18v19.57c0 1.202.923 2.177 2.06 2.179L93.91 56c1.14.001 2.064-.973 2.065-2.176L96 34.182ZM58.78 78.773 35.167 66.247C33.71 65.474 32 66.61 32 68.353v25.294c0 1.75 1.723 2.887 3.182 2.098l23.613-12.768c1.614-.873 1.605-3.344-.015-4.204ZM73 69a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H73Zm-1 17a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H73a1 1 0 0 1-1-1v-6Z"
        fill="currentColor"
      />
    </svg>
  )
}
