import * as React from 'react'
import { SVGProps } from 'react'
export const IconTagFree = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0v104.174c0 2.83 2.857 4.764 5.484 3.715l59.809-23.897 57.203 23.218c2.63 1.067 5.504-.868 5.504-3.707V0H0Zm41.436 44.44v4.824h-8.604V59.2H27V34h14.436v4.824h-8.604v5.616h8.604Zm8.607 14.76H44.21V34h8.208c4.14 0 9.432 2.124 9.432 7.668 0 3.78-2.484 5.94-5.22 7.02l1.627 2.258.003.005c2.11 2.93 4.643 6.448 5.966 8.249h-7.272l-6.66-9.828h-.252V59.2Zm2.232-20.376h-2.232v5.724h1.548c2.34 0 4.068-.828 4.068-2.772 0-1.764-1.368-2.952-3.384-2.952ZM82.869 59.2v-4.824H72.14v-5.724h10.728v-4.824H72.14v-5.004h10.728V34h-16.56v25.2h16.56Zm19.882-4.824V59.2h-16.56V34h16.56v4.824H92.023v5.004h10.728v4.824H92.023v5.724h10.728Z"
        fill="currentColor"
      />
    </svg>
  )
}
