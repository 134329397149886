import * as React from 'react'
import { SVGProps } from 'react'
export const IconSummaryTabbarShare = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m53.568 20.77 9.3-9.3a1.64 1.64 0 0 1 2.333.034l9.3 9.3c1.067 1.034.333 2.834-1.167 2.834h-5.966v32.916c0 .69-.56 1.25-1.25 1.25H61.95c-.69 0-1.25-.56-1.25-1.25V23.638h-5.966c-1.5 0-2.233-1.8-1.166-2.867Zm-1.443 10.855H42.75a5 5 0 0 0-5 5V76a5 5 0 0 0 5 5h42.5a5 5 0 0 0 5-5V36.625a5 5 0 0 0-5-5h-9.375c-.69 0-1.25.56-1.25 1.25v4.375c0 .69.56 1.25 1.25 1.25h7.5v35.625h-38.75V38.5h7.5c.69 0 1.25-.56 1.25-1.25v-4.375c0-.69-.56-1.25-1.25-1.25Z"
        fill="currentColor"
      />
      <path
        d="M49.795 102.814c-1.007 1.273-3.021 2.831-4.807 3.781.532.361 1.368 1.083 1.862 1.577 1.824-1.083 3.914-2.793 5.339-4.427l-2.394-.931Zm5.016 1.064c1.691 1.292 3.952 3.135 4.997 4.294l2.242-1.33c-1.197-1.159-3.553-2.926-5.168-4.085l-2.071 1.121Zm-3.496-7.638h4.37v3.648h-4.37V96.24Zm6.726 3.648V96.24h3.553v-2.185h-3.553V90.54h-2.356v3.515h-4.37V90.54h-2.356v3.515h-3.42v2.185h3.42v3.648h-4.085v2.204h17.309v-2.204h-4.142ZM77.053 99.508h-6.859v-1.216h6.859v1.216Zm-6.859 3.116v-1.235h6.859v1.235h-6.859Zm11.191-7.809v-2.128h-9.272a32.4 32.4 0 0 0 .608-1.767l-2.337-.532c-.19.76-.437 1.52-.741 2.3h-5.111v2.127h4.161c-1.14 2.204-2.717 4.2-4.731 5.548.437.418 1.159 1.235 1.482 1.729.931-.627 1.748-1.387 2.508-2.223v8.265h2.242v-3.629h6.859v1.14c0 .266-.114.361-.437.38-.323 0-1.425 0-2.394-.057.285.608.589 1.558.684 2.185 1.539 0 2.622-.019 3.363-.38.779-.342.988-.95.988-2.071v-9.386H70.46c.285-.494.532-.988.76-1.5h10.165Z"
        fill="currentColor"
      />
    </svg>
  )
}
