import { SVGProps } from 'react'
interface Props extends SVGProps<SVGSVGElement> {
  className?: string
}

export const Inventory2Dark = (props: Props): JSX.Element => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M106.667 10.667H21.333c-5.333 0-10.666 4.8-10.666 10.666v16.054c0 3.84 2.293 7.146 5.333 9.013v60.267c0 5.866 5.866 10.666 10.666 10.666h74.667c4.8 0 10.667-4.8 10.667-10.666V46.4c3.04-1.867 5.333-5.173 5.333-9.013V21.333c0-5.866-5.333-10.666-10.666-10.666Zm-32 64H53.332c-2.933 0-5.333-2.4-5.333-5.334C48 66.4 50.4 64 53.333 64h21.334C77.6 64 80 66.4 80 69.333c0 2.934-2.4 5.334-5.334 5.334Zm32-37.334H21.333v-16h85.334v16Z"
        fill="#1A6DCC"
      />
    </svg>
  )
}
