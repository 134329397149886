interface Props {
  color?: string
  className?: string
  thickness?: string // 太さ
  width?: string
}
export const Plus: React.FC<Props> = ({
  color = '#666',
  className = '',
  thickness = '1px',
  width = '10px',
}) => {
  return (
    <div className={`${className} plus`}>
      <style jsx>{`
        .plus {
          display: inline-block;
          vertical-align: middle;
          color: ${color};
          line-height: 1;
          width: ${width};
          height: ${thickness};
          background: currentColor;
          border-radius: 0.1em;
          position: relative;
        }

        .plus::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: inherit;
          border-radius: inherit;
          transform: rotate(90deg);
        }
      `}</style>
    </div>
  )
}
