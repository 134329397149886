import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryIt = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_it_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M100.148 72.258c0 4.63-3.755 8.399-8.367 8.399H35.77c-4.612 0-8.367-3.77-8.367-8.4V44.91c0-4.63 3.755-8.399 8.367-8.399H91.78c4.612 0 8.367 3.77 8.367 8.399v27.348Z"
          fill="#fff"
        />
        <path
          d="M109.609 94.68H17.956A2.97 2.97 0 0 1 15 91.712V85.85a2.97 2.97 0 0 1 2.956-2.967h91.653a2.97 2.97 0 0 1 2.956 2.967v5.862a2.96 2.96 0 0 1-2.956 2.968Z"
          fill="#00253F"
        />
        <path
          d="M101.567 81.918h-75.45V37.194a2.97 2.97 0 0 1 2.956-2.968H98.61a2.971 2.971 0 0 1 2.957 2.968v44.724Z"
          fill="#fff"
        />
        <path
          d="M103.784 84.144H23.899v-46.95c0-2.864 2.32-5.194 5.174-5.194H98.61c2.853 0 5.174 2.33 5.174 5.194v46.95Zm-75.45-4.452H99.35V37.194a.733.733 0 0 0-.74-.742H29.073a.733.733 0 0 0-.74.742v42.498Z"
          fill="#00253F"
        />
        <path
          d="M78.403 55.282a9.84 9.84 0 0 0 .251-2.21c0-5.462-4.405-9.884-9.845-9.884-4.671 0-8.56 3.265-9.58 7.642a6.751 6.751 0 0 0-2.941-.682c-3.77 0-6.83 3.072-6.83 6.855 0 .282.015.55.06.817-.134 0-.267-.015-.4-.015-4.375 0-7.923 3.561-7.923 7.953 0 4.393 3.548 7.954 7.923 7.954h32.61c2.617-1.78 4.643-5.327 4.643-8.74a9.864 9.864 0 0 0-7.968-9.69Z"
          fill="#0081CC"
        />
      </g>
      <defs>
        <clipPath id="icon_category_it_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
