import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryMind = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_mind_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M98.343 51.758c-.015-1.736-.03-3.487-.03-5.253C98.313 38.373 92.15 23 68.852 23c-9.284 0-30.867 2.181-30.867 22.333v11.425L27.712 70.662a2.247 2.247 0 0 0-.207 2.33 2.2 2.2 0 0 0 1.98 1.232h8.959v4.778h12.535v2.834H38.444v2.315c0 9.007 9.727 9.007 12.934 9.007.09 0 .207 0 .4.223 1.581 1.721 1.877 8.28 1.581 12.153l4.42.356c0-.059.015-.148.015-.208l24.554-.4h2.218v-8.518c0-6.766 1.448-8.117 5.336-11.737a88.1 88.1 0 0 0 2.868-2.746c5.765-5.772 5.676-17.242 5.573-30.523Z"
          fill="#00253F"
        />
        <path
          d="M48.954 65.202a2.547 2.547 0 0 0 2.543-2.552 2.547 2.547 0 0 0-2.543-2.553 2.547 2.547 0 0 0-2.542 2.553 2.547 2.547 0 0 0 2.542 2.552Z"
          fill="#fff"
        />
        <path
          d="M80.604 42.825c-.325 0-.65-.104-.931-.327a8.693 8.693 0 0 0-5.559-1.973 8.689 8.689 0 0 0-4.213 1.083 1.48 1.48 0 0 1-2.01-.594 1.483 1.483 0 0 1 .591-2.018c4.065-2.226 9.446-1.75 13.053 1.202.636.52.724 1.455.222 2.093a1.49 1.49 0 0 1-1.153.534ZM74.114 61.196c-4.08 0-7.805-2.078-9.978-5.535a1.504 1.504 0 0 1 .458-2.048 1.489 1.489 0 0 1 2.04.46 8.783 8.783 0 0 0 7.48 4.155 8.867 8.867 0 0 0 6.623-2.998 1.485 1.485 0 0 1 2.084-.133 1.5 1.5 0 0 1 .133 2.092 11.783 11.783 0 0 1-8.84 4.007ZM53.966 55.32c-4.938 0-8.959-4.037-8.959-8.993s4.021-8.992 8.959-8.992a8.97 8.97 0 0 1 5.839 2.166c.62.534.694 1.469.162 2.092a1.473 1.473 0 0 1-2.084.163 5.97 5.97 0 0 0-3.903-1.454c-3.31 0-6.001 2.7-6.001 6.025 0 3.324 2.69 6.024 6.001 6.024a6.04 6.04 0 0 0 3.223-.934 1.474 1.474 0 0 1 2.04.445 1.478 1.478 0 0 1-.458 2.047 8.954 8.954 0 0 1-4.82 1.41Z"
          fill="#0081CC"
        />
        <path
          d="M83.753 57.916c-3.622 0-6.77-2.582-7.495-6.128a1.487 1.487 0 0 1 1.153-1.751 1.481 1.481 0 0 1 1.745 1.157 4.704 4.704 0 0 0 4.597 3.754c2.587 0 4.686-2.107 4.686-4.704a4.676 4.676 0 0 0-3.592-4.555 1.514 1.514 0 0 1-1.02-.876 1.502 1.502 0 0 1 .118-1.35c.606-.98.931-2.077.931-3.19 0-3.324-2.69-6.025-6.001-6.025-1.316 0-2.573.43-3.622 1.217a1.463 1.463 0 0 1-2.07-.282c-.488-.653-.37-1.588.281-2.078a8.819 8.819 0 0 1 5.41-1.825c4.938 0 8.959 4.036 8.959 8.993a8.879 8.879 0 0 1-.591 3.16 7.644 7.644 0 0 1 4.153 6.811c0 4.23-3.43 7.672-7.642 7.672Z"
          fill="#0081CC"
        />
        <path
          d="M51.468 40.302a1.485 1.485 0 0 1-1.479-1.484c0-4.229 3.43-7.671 7.643-7.671a7.52 7.52 0 0 1 3.533.89 8.93 8.93 0 0 1 6.549-2.864c4.937 0 8.958 4.036 8.958 8.993 0 .816-.665 1.483-1.478 1.483a1.485 1.485 0 0 1-1.479-1.483c0-3.324-2.69-6.025-6.001-6.025a5.988 5.988 0 0 0-4.997 2.7 1.554 1.554 0 0 1-1.005.654 1.47 1.47 0 0 1-1.153-.312 4.577 4.577 0 0 0-2.927-1.054c-2.587 0-4.686 2.107-4.686 4.704 0 .816-.665 1.47-1.478 1.47ZM63.175 56.655c-4.213 0-7.642-3.443-7.642-7.672 0-.816.665-1.484 1.478-1.484s1.478.668 1.478 1.484c0 2.597 2.1 4.704 4.686 4.704 1.641 0 3.134-.846 4.006-2.256a1.484 1.484 0 0 1 2.04-.49c.695.431.917 1.336.488 2.048-1.419 2.286-3.858 3.666-6.534 3.666Z"
          fill="#0081CC"
        />
      </g>
      <defs>
        <clipPath id="icon_category_mind_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
