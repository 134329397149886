import * as React from 'react'
import { SVGProps } from 'react'
export const IconSend = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m19.58 108.8 93.067-39.894c4.32-1.866 4.32-7.946 0-9.813L19.58 19.2c-3.52-1.547-7.413 1.066-7.413 4.853l-.054 24.587c0 2.666 1.974 4.96 4.64 5.28L92.113 64l-75.36 10.026a5.396 5.396 0 0 0-4.64 5.334l.054 24.586c0 3.787 3.893 6.4 7.413 4.854Z"
        fill="currentColor"
      />
    </svg>
  )
}
