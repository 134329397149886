import * as React from 'react'
import { SVGProps } from 'react'
export const IconStar = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m64 93.438 22.134 13.386c4.053 2.454 9.013-1.173 7.946-5.76l-5.866-25.173 19.573-16.96c3.573-3.093 1.653-8.96-3.04-9.333l-25.76-2.187-10.08-23.787c-1.813-4.32-8-4.32-9.813 0l-10.08 23.734-25.76 2.186c-4.694.374-6.614 6.24-3.04 9.334l19.573 16.96-5.867 25.173c-1.066 4.587 3.894 8.213 7.947 5.76L64 93.438Z"
        fill="currentColor"
      />
    </svg>
  )
}
