import * as React from 'react'
import { SVGProps } from 'react'
export const IconThumbUp = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M69.062 14.677 39.515 44.223a10.656 10.656 0 0 0-3.093 7.52v53.28c0 5.867 4.8 10.667 10.667 10.667h48c4.266 0 8.106-2.56 9.813-6.453l17.387-40.587c4.48-10.56-3.254-22.293-14.72-22.293H77.435l5.067-24.427c.533-2.667-.267-5.387-2.187-7.307-3.147-3.093-8.16-3.093-11.253.054ZM15.088 115.69c5.867 0 10.667-4.8 10.667-10.667V62.357c0-5.867-4.8-10.667-10.666-10.667-5.867 0-10.667 4.8-10.667 10.667v42.666c0 5.867 4.8 10.667 10.666 10.667Z"
        fill="currentColor"
      />
    </svg>
  )
}
