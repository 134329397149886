import * as React from 'react'
import { SVGProps } from 'react'
export const IconBookshelf = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.295 16.843H32a4 4 0 0 1 4 4v88.249c0 2.075-1.48 3.756-3.302 3.751l-21.412-.141C9.47 112.697 8 111.019 8 108.952V20.593c0-2.071 1.476-3.75 3.295-3.75ZM45.295 16.843H66a4 4 0 0 1 4 4v88.249c0 2.075-1.48 3.756-3.302 3.751l-21.412-.141c-1.816-.005-3.286-1.683-3.286-3.75V20.593c0-2.071 1.476-3.75 3.295-3.75ZM78.034 18.714l20.325-3.95a4 4 0 0 1 4.69 3.163l16.838 86.628c.396 2.037-.736 3.969-2.526 4.311l-21.045 3.948c-1.784.341-3.547-1.025-3.941-3.055L75.516 23.024c-.395-2.033.732-3.963 2.518-4.31Z"
        fill="currentColor"
      />
    </svg>
  )
}
