import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryMarketing = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_marketing_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M38.98 70.423H28.276A2.282 2.282 0 0 0 26 72.708v28.729a2.282 2.282 0 0 0 2.276 2.285H38.98a2.282 2.282 0 0 0 2.277-2.285V72.709a2.282 2.282 0 0 0-2.277-2.286ZM58.492 41.16H47.79a2.282 2.282 0 0 0-2.277 2.286v57.99a2.282 2.282 0 0 0 2.277 2.286h10.702a2.282 2.282 0 0 0 2.277-2.286v-57.99a2.282 2.282 0 0 0-2.277-2.285Z"
          fill="#00253F"
        />
        <path
          d="M78.005 23.636H67.302a2.282 2.282 0 0 0-2.276 2.285v75.516a2.282 2.282 0 0 0 2.276 2.285h10.703a2.282 2.282 0 0 0 2.277-2.285V25.921a2.282 2.282 0 0 0-2.277-2.285Z"
          fill="#0081CC"
        />
        <path
          d="M84.554 86.76c9.348 0 16.926-7.606 16.926-16.99 0-9.383-7.578-16.99-16.926-16.99-9.348 0-16.926 7.607-16.926 16.99 0 9.384 7.578 16.99 16.926 16.99Z"
          fill="#fff"
        />
        <path
          d="M86.476 88.987c-10.555 0-19.144-8.622-19.144-19.217 0-10.595 8.589-19.216 19.144-19.216 10.554 0 19.143 8.621 19.143 19.216S97.03 88.987 86.476 88.987Zm0-33.981c-8.116 0-14.71 6.618-14.71 14.764 0 8.147 6.594 14.765 14.71 14.765 8.115 0 14.708-6.618 14.708-14.765 0-8.146-6.593-14.764-14.708-14.764Z"
          fill="#00253F"
        />
        <path
          d="m98.23 84.236-3.616 2.578 9.853 13.924 3.616-2.578-9.853-13.924Z"
          fill="#00253F"
        />
      </g>
      <defs>
        <clipPath id="icon_category_marketing_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
