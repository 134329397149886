import * as React from 'react'
import { SVGProps } from 'react'
export const IconSettings = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 64c0-1.227-.053-2.4-.16-3.627l9.92-7.52c2.133-1.6 2.72-4.587 1.387-6.933l-9.974-17.227c-1.333-2.347-4.213-3.306-6.666-2.24L87.04 31.307a40.472 40.472 0 0 0-6.24-3.627l-1.546-12.32c-.32-2.667-2.614-4.694-5.28-4.694H54.08c-2.72 0-5.013 2.027-5.333 4.694L47.2 27.68a40.463 40.463 0 0 0-6.24 3.627l-11.466-4.854c-2.454-1.066-5.334-.107-6.667 2.24l-9.974 17.28a5.377 5.377 0 0 0 1.387 6.934l9.92 7.52A39.15 39.15 0 0 0 24 64c0 1.227.053 2.4.16 3.626l-9.92 7.52c-2.133 1.6-2.72 4.587-1.386 6.934l9.973 17.227c1.333 2.346 4.213 3.306 6.666 2.24l11.467-4.854a40.48 40.48 0 0 0 6.24 3.627l1.547 12.32c.32 2.667 2.613 4.693 5.28 4.693H73.92c2.667 0 4.96-2.026 5.28-4.693l1.547-12.32a40.48 40.48 0 0 0 6.24-3.627l11.467 4.854c2.453 1.066 5.333.106 6.666-2.24l9.973-17.227a5.376 5.376 0 0 0-1.386-6.933l-9.92-7.52c.16-1.227.213-2.4.213-3.627ZM64.213 82.666c-10.293 0-18.666-8.373-18.666-18.666 0-10.294 8.373-18.667 18.666-18.667 10.294 0 18.667 8.373 18.667 18.667 0 10.293-8.373 18.666-18.666 18.666Z"
        fill="currentColor"
      />
    </svg>
  )
}
