import * as React from 'react'
import { SVGProps } from 'react'
export const IconCircleBookmark = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M77.334 40H50.666c-2.934 0-5.307 2.4-5.307 5.333L45.334 88 64 80l18.667 8V45.333c0-2.933-2.4-5.333-5.334-5.333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 64.335c0 27.8 22.536 50.335 50.335 50.335 27.8 0 50.335-22.536 50.335-50.335C114.67 36.535 92.134 14 64.335 14 36.535 14 14 36.536 14 64.335ZM64.335 11C34.879 11 11 34.879 11 64.335s23.879 53.335 53.335 53.335 53.335-23.879 53.335-53.335S93.791 11 64.335 11Z"
        fill="currentColor"
      />
    </svg>
  )
}
