import { SVGProps } from 'react'

export const IconBusinessBlueGradation = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <path
        d="M13.0001 7.8717V4.7307C13.0001 4.19966 12.5696 3.76916 12.0386 3.76917L3.70519 3.76919C3.17415 3.76919 2.74365 4.19968 2.74365 4.73073V21.2691C2.74365 21.8002 3.17415 22.2307 3.70519 22.2307H22.295C22.826 22.2307 23.2565 21.8002 23.2565 21.2691V8.83324C23.2565 8.30219 22.826 7.8717 22.295 7.8717H13.0001ZM6.84625 20.1794H4.79496V18.1281H6.84625V20.1794ZM6.84625 16.0768H4.79496V14.0255H6.84625V16.0768ZM6.84625 11.9743H4.79496V9.92298H6.84625V11.9743ZM6.84625 7.8717H4.79496V5.82042H6.84625V7.8717ZM10.9488 20.1794H8.89753V18.1281H10.9488V20.1794ZM10.9488 16.0768H8.89753V14.0255H10.9488V16.0768ZM10.9488 11.9743H8.89753V9.92298H10.9488V11.9743ZM10.9488 7.8717H8.89753V5.82042H10.9488V7.8717ZM21.2052 19.6025C21.2052 19.9211 20.9469 20.1794 20.6283 20.1794H13.0001V18.1281H15.0514V16.0768H13.0001V14.0255H15.0514V11.9743H13.0001V9.92298H20.6283C20.9469 9.92298 21.2052 10.1813 21.2052 10.4999V19.6025ZM19.1539 11.9743H17.1027V14.0255H19.1539V11.9743ZM19.1539 16.0768H17.1027V18.1281H19.1539V16.0768Z"
        fill="url(#paint0_linear_374_45694)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_374_45694"
          x1="28.464"
          y1="-2.78709"
          x2="7.01443"
          y2="25.4911"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.20622" stopColor="#408BED" />
          <stop offset="1" stopColor="#044DAB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
