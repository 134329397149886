import * as React from 'react'
import { SVGProps } from 'react'
export const XRound = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={12.5}
        cy={12.877}
        r={12.5}
        fill="#080404"
        style={{
          fill: 'color(display-p3 .0314 .0157 .0157)',
          fillOpacity: 1,
        }}
      />
      <circle
        cx={12.5}
        cy={12.877}
        r={12.305}
        stroke="#C4C4C4"
        strokeOpacity={0.5}
        style={{
          stroke: 'color(display-p3 .7686 .7686 .7686)',
          strokeOpacity: 0.5,
        }}
        strokeWidth={0.391}
      />
      <path
        d="m13.88 11.83 5.022-5.79h-1.19l-4.36 5.027L9.87 6.041H5.854l5.266 7.6-5.266 6.071h1.19l4.604-5.308 3.677 5.308h4.016l-5.46-7.882Zm-1.629 1.879-.533-.757-4.245-6.023H9.3l3.426 4.86.533.758 4.453 6.317h-1.827L12.25 13.71Z"
        fill="#fff"
        style={{
          fill: '#fff',
          fillOpacity: 1,
        }}
      />
    </svg>
  )
}
