interface Props {
  className?: string
}
export const MenuFeature: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 1.875V18.125H18.125V1.875H1.875ZM14.7252 5.24902H5.23682V8.62577H14.7252V5.24902ZM5.23682 10.6744H8.62494V14.3608H5.23682V10.6744ZM14.725 10.6744H9.94263V11.6876H14.725V10.6744ZM9.94263 13.3466H14.725V14.3597H9.94263V13.3466Z"
        fill="#1A6DCC"
      />
    </svg>
  )
}
