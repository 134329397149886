import * as React from 'react'
import { SVGProps } from 'react'
export const IconTabbarMypageCircle = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.666 64c0-29.334 24-53.334 53.334-53.334 29.333 0 53.333 24 53.333 53.334 0 29.333-24 53.333-53.333 53.333-29.334 0-53.334-24-53.334-53.333ZM79 46.5c0 8.267-6.733 15-15 15s-15-6.733-15-15 6.733-15 15-15 15 6.733 15 15Zm-40.625 45c0-13.807 11.193-25 25-25h1.25c13.807 0 25 11.193 25 25V94a2.5 2.5 0 0 1-2.5 2.5h-46.25a2.5 2.5 0 0 1-2.5-2.5v-2.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
