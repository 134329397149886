import * as React from 'react'
import { SVGProps } from 'react'
export const IconPause = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.667 101.333c5.866 0 10.666-4.8 10.666-10.666V37.332c0-5.866-4.8-10.666-10.666-10.666C36.8 26.666 32 31.466 32 37.332v53.334c0 5.866 4.8 10.666 10.667 10.666Zm32-64v53.334c0 5.866 4.8 10.666 10.666 10.666 5.867 0 10.667-4.8 10.667-10.666V37.332c0-5.866-4.8-10.666-10.667-10.666-5.866 0-10.666 4.8-10.666 10.666Z"
        fill="currentColor"
      />
    </svg>
  )
}
