import * as React from 'react'
import { SVGProps } from 'react'
export const IconCircleSendOutline = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="icon_circle_send_outline_svg__a" fill="#fff">
        <path d="M64 10.666c-29.334 0-53.334 24-53.334 53.334 0 29.333 24 53.333 53.334 53.333 29.333 0 53.333-24 53.333-53.333 0-29.334-24-53.334-53.333-53.334Z" />
      </mask>
      <path
        d="M64 7.667C33.01 7.667 7.666 33.01 7.666 64h6c0-27.677 22.657-50.334 50.334-50.334v-6ZM7.666 64c0 30.99 25.344 56.333 56.334 56.333v-6c-27.677 0-50.334-22.657-50.334-50.333h-6ZM64 120.333c30.99 0 56.333-25.343 56.333-56.333h-6c0 27.676-22.657 50.333-50.333 50.333v6ZM120.333 64C120.333 33.01 94.99 7.666 64 7.666v6c27.676 0 50.333 22.657 50.333 50.334h6Z"
        fill="currentColor"
        mask="url(#icon_circle_send_outline_svg__a)"
      />
      <path
        d="m44.79 86.4 46.533-19.947c2.16-.933 2.16-3.973 0-4.907L44.79 41.6c-1.76-.773-3.707.533-3.707 2.426l-.026 12.294c0 1.333.986 2.48 2.32 2.64L81.057 64l-37.68 5.013a2.698 2.698 0 0 0-2.32 2.667l.026 12.293c0 1.894 1.947 3.2 3.707 2.427Z"
        fill="currentColor"
      />
    </svg>
  )
}
