import { SVGProps } from 'react'

export const IconClock = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        d="M25.3438 15.7584C24.3735 22.0217 18.5095 26.3143 12.2439 25.3437C5.97807 24.3731 1.68542 18.5091 2.65596 12.2439C3.62654 5.97841 9.49268 1.68604 15.7584 2.65665C22.0238 3.62721 26.314 9.49493 25.3438 15.7584Z"
        fill="white"
        stroke="#090800"
        strokeWidth="1.57576"
      />
      <path
        d="M13.5948 16.6238C13.4864 16.607 13.3826 16.5747 13.2858 16.5273C13.1009 16.4362 12.9441 16.2985 12.8342 16.1265C12.7224 15.9518 12.663 15.7529 12.6602 15.5465L12.5409 7.33907C12.5317 7.18955 12.5545 7.04267 12.604 6.90225C12.6558 6.76219 12.7336 6.6331 12.8358 6.52629C12.9361 6.41687 13.0584 6.32937 13.1949 6.26953C13.3314 6.2097 13.4777 6.17681 13.6261 6.17436C13.7746 6.17191 13.9231 6.19953 14.0602 6.25548C14.1996 6.31178 14.3231 6.39571 14.4292 6.50239C14.5331 6.60872 14.6153 6.73484 14.6703 6.87294C14.7253 7.01103 14.751 7.15849 14.7463 7.30817L14.8304 13.2934L19.1429 9.99591C19.3749 9.82821 19.6616 9.75461 19.9448 9.79385C20.228 9.83309 20.4849 9.98165 20.6608 10.2056C20.839 10.4299 20.9219 10.7158 20.8918 11.0003C20.8616 11.2849 20.7239 11.5459 20.5054 11.7318L14.4228 16.4027C14.3047 16.4931 14.1716 16.5604 14.0314 16.5989C13.889 16.637 13.7395 16.6462 13.5945 16.626L13.5948 16.6238Z"
        fill="url(#paint0_linear_498_1026)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_498_1026"
          x1="24.2353"
          y1="4.08246"
          x2="9.96019"
          y2="14.674"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.20622" stopColor="#408BED" />
          <stop offset="1" stopColor="#044DAB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
