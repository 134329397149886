import * as React from 'react'
import { SVGProps } from 'react'
export const IconCheck = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.907 84.667 28.4 66.16a5.311 5.311 0 0 0-7.52 0 5.311 5.311 0 0 0 0 7.52l22.294 22.293a5.311 5.311 0 0 0 7.52 0l56.426-56.426a5.311 5.311 0 0 0 0-7.52 5.31 5.31 0 0 0-7.52 0l-52.693 52.64Z"
        fill="currentColor"
      />
    </svg>
  )
}
