interface Props {
  className?: string
}
export const MenuRanking: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.208 9.55029L10.5986 3.91418C10.562 3.78599 10.4839 3.67312 10.3762 3.5927C10.2684 3.51229 10.137 3.46875 10.0018 3.46875C9.86667 3.46875 9.73522 3.51229 9.6275 3.5927C9.51977 3.67312 9.44168 3.78599 9.40511 3.91418L7.79563 9.55029C7.69853 9.89031 7.51043 10.1984 7.25103 10.4422C6.99163 10.6861 6.67046 10.8567 6.32114 10.9363C5.97183 11.0159 5.60721 11.0016 5.26545 10.8948C4.92369 10.788 4.61737 10.5926 4.37854 10.3292L1.69827 7.37357C1.61751 7.28393 1.50932 7.22257 1.39006 7.19885C1.2708 7.17513 1.14695 7.19027 1.0372 7.24202C0.927461 7.29377 0.837793 7.37934 0.781748 7.48572C0.725703 7.59211 0.706324 7.71357 0.726541 7.83177L2.03318 15.5712C2.07852 15.8394 2.21897 16.0831 2.42955 16.2589C2.64014 16.4347 2.9072 16.5312 3.18322 16.5312H16.8195C17.0955 16.5312 17.3626 16.4347 17.5732 16.2589C17.7838 16.0831 17.9242 15.8394 17.9695 15.5712L19.2762 7.83177C19.2922 7.71489 19.2701 7.59601 19.2133 7.49225C19.1564 7.38848 19.0676 7.30519 18.9596 7.25427C18.8516 7.20336 18.73 7.18746 18.6123 7.20889C18.4946 7.23033 18.3868 7.288 18.3045 7.37357L15.6242 10.3292C15.3853 10.5924 15.0791 10.7875 14.7374 10.8942C14.3958 11.0009 14.0313 11.0151 13.6822 10.9355C13.333 10.8559 13.012 10.6853 12.7527 10.4417C12.4933 10.198 12.3052 9.89012 12.208 9.55029Z"
        fill="#1A6DCC"
      />
    </svg>
  )
}
