import * as React from 'react'
import { SVGProps } from 'react'
export const IconFree = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m64.026 40.165-6.043-4.585c-5.21-3.952-13.823-5.967-23.29-5.967-6.853 0-13.543 1.058-18.73 3.336a1.21 1.21 0 0 0-.494.405.577.577 0 0 0-.11.34v60.16c0 .147.031.238.065.305a.714.714 0 0 0 .447.361.51.51 0 0 0 .251-.012c6.153-1.569 12.541-2.228 18.571-2.228 9.236 0 19.755 1.51 28.739 5.936l.027.013.026.013c.238.12.416.145.535.145a.936.936 0 0 0 .434-.114l.03-.016.03-.015c9.043-4.521 19.61-5.962 28.793-5.962 6.032 0 12.423.66 18.578 2.23l.031.008.031.008a.445.445 0 0 0 .222.015.542.542 0 0 0 .209-.105.704.704 0 0 0 .19-.23.755.755 0 0 0 .072-.353v-60.16a.578.578 0 0 0-.109-.339 1.212 1.212 0 0 0-.502-.408c-5.117-2.27-11.8-3.333-18.67-3.333-9.466 0-18.079 2.015-23.289 5.967l-6.043 4.585Zm52.107-16.338c3.894 1.76 6.507 5.546 6.507 9.866v60.16c0 6.987-6.507 12.107-13.227 10.347-5.226-1.333-10.773-1.92-16.106-1.92-8.32 0-17.174 1.333-24.32 4.907-3.147 1.6-6.774 1.6-9.974 0-7.146-3.52-16-4.907-24.32-4.907-5.333 0-10.88.587-16.106 1.92-6.72 1.707-13.227-3.413-13.227-10.347v-60.16c0-4.32 2.613-8.106 6.506-9.866 6.88-3.04 15.094-4.214 22.827-4.214 10.4 0 21.6 2.134 29.334 8 7.733-5.866 18.933-8 29.333-8 7.733 0 15.947 1.174 22.773 4.214Z"
        fill="currentColor"
      />
      <path
        d="M40.436 67.264V62.44h-8.604v-5.616h8.604V52H26v25.2h5.832v-9.936h8.604ZM43.21 77.2h5.833v-9.828h.252l6.66 9.828h7.272c-1.692-2.304-5.364-7.416-7.596-10.512 2.736-1.08 5.22-3.24 5.22-7.02 0-5.544-5.292-7.668-9.432-7.668H43.21v25.2Zm5.833-20.376h2.232c2.016 0 3.384 1.188 3.384 2.952 0 1.944-1.728 2.772-4.068 2.772h-1.548v-5.724ZM81.869 77.2v-4.824H71.14v-5.724h10.728v-4.824H71.14v-5.004h10.728V52h-16.56v25.2h16.56ZM101.751 77.2v-4.824H91.023v-5.724h10.728v-4.824H91.023v-5.004h10.728V52h-16.56v25.2h16.56Z"
        fill="currentColor"
      />
    </svg>
  )
}
