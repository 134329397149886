interface Props {
  className?: string
}

export const AudioSkipForward: React.FC<Props> = ({ className = '' }) => {
  return (
    <img
      className={className}
      src={`${process.env.IMG_SERVER}/icon/fastforward_15sec.png`}
      alt=""
    />
  )
}
