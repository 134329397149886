import * as React from 'react'
import { SVGProps } from 'react'
export const IconClose = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M97.6 30.453a5.311 5.311 0 0 0-7.52 0L64 56.48 37.92 30.4a5.311 5.311 0 0 0-7.52 0 5.311 5.311 0 0 0 0 7.52L56.48 64 30.4 90.08a5.311 5.311 0 0 0 0 7.52 5.311 5.311 0 0 0 7.52 0L64 71.52 90.08 97.6a5.311 5.311 0 0 0 7.52 0 5.311 5.311 0 0 0 0-7.52L71.52 64 97.6 37.92c2.026-2.027 2.026-5.44 0-7.467Z"
        fill="currentColor"
      />
    </svg>
  )
}
