interface Props {
  color?: string
}

export const Clip: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width={9}
      height={18}
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.80019 4.20001V13.4C7.80019 15.168 6.3682 16.6 4.60019 16.6C2.8322 16.6 1.4002 15.168 1.4002 13.4V3.40001C1.4002 2.29601 2.2962 1.40001 3.4002 1.40001C4.5042 1.40001 5.4002 2.29601 5.4002 3.40001V11.8C5.4002 12.24 5.04019 12.6 4.60019 12.6C4.1602 12.6 3.8002 12.24 3.8002 11.8V4.20001H2.6002V11.8C2.6002 12.904 3.4962 13.8 4.60019 13.8C5.7042 13.8 6.60019 12.904 6.60019 11.8V3.40001C6.60019 1.63201 5.1682 0.200012 3.4002 0.200012C1.6322 0.200012 0.200195 1.63201 0.200195 3.40001V13.4C0.200195 15.832 2.1682 17.8 4.60019 17.8C7.0322 17.8 9.00019 15.832 9.00019 13.4V4.20001H7.80019Z"
        fill={color || '#666666'}
      />
    </svg>
  )
}
