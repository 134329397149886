import * as React from 'react'
import { SVGProps } from 'react'
export const IconClip = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M71.754 20.422a2.02 2.02 0 0 0-.56.373l-48.72 48.737a25.76 25.76 0 0 0-1.323 35.636 25.228 25.228 0 0 0 18.242 8.282h.628A25.219 25.219 0 0 0 57.992 106l49.212-49.212c.153-.153.269-.338.339-.543A25.453 25.453 0 0 0 89.118 14.77a25.455 25.455 0 0 0-17.364 5.652Zm27.423 30.002L64.661 84.941l-.187.17a7.738 7.738 0 0 1-10.012.491 7.465 7.465 0 0 1-.814-10.843L82.19 46.182a5.093 5.093 0 1 0-7.213-7.195L46.13 67.835a1.36 1.36 0 0 0-.255.357 17.631 17.631 0 0 0 .798 24.113 17.835 17.835 0 0 0 6.483 4.141l-1.952 1.952a15.633 15.633 0 0 1-21.144 1.12 15.07 15.07 0 0 1-5.227-10.997 15.272 15.272 0 0 1 4.446-11.319l48.194-48.194.628-.543a15.273 15.273 0 0 1 10.012-3.699c.601 0 1.202.034 1.799.102a15.12 15.12 0 0 1 10.64 6.296 15.714 15.714 0 0 1-1.375 19.26Z"
        fill="currentColor"
      />
    </svg>
  )
}
