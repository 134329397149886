import * as React from 'react'
import { SVGProps } from 'react'
export const IconMenuBook = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M93.36 19.613c-10.4 0-21.6 2.134-29.333 8-7.733-5.866-18.933-8-29.333-8-7.734 0-15.947 1.174-22.827 4.214-3.893 1.76-6.507 5.546-6.507 9.866v60.16c0 6.934 6.507 12.054 13.227 10.347 5.227-1.333 10.773-1.92 16.107-1.92 8.32 0 17.173 1.387 24.32 4.907 3.2 1.6 6.826 1.6 9.973 0 7.147-3.574 16-4.907 24.32-4.907 5.333 0 10.88.587 16.107 1.92 6.72 1.76 13.226-3.36 13.226-10.347v-60.16c0-4.32-2.613-8.106-6.506-9.866-6.827-3.04-15.04-4.214-22.774-4.214Zm18.667 67.894c0 3.36-3.093 5.813-6.4 5.226-4-.746-8.16-1.066-12.267-1.066-9.066 0-22.133 3.466-29.333 8V38.28c7.2-4.533 20.267-8 29.333-8 4.907 0 9.76.48 14.4 1.493 2.454.534 4.267 2.72 4.267 5.227v50.507Z"
        fill="currentColor"
      />
      <path
        d="M74.587 54.333c-1.707 0-3.253-1.066-3.787-2.773-.693-2.08.48-4.373 2.56-5.013 8.214-2.667 18.827-3.52 28.587-2.4 2.187.266 3.787 2.24 3.52 4.426-.267 2.187-2.24 3.787-4.427 3.52-8.64-1.013-18.08-.213-25.226 2.08-.427.054-.854.16-1.227.16ZM74.587 68.52c-1.707 0-3.253-1.067-3.787-2.773-.693-2.08.48-4.374 2.56-5.014 8.16-2.666 18.827-3.52 28.587-2.4 2.187.267 3.787 2.24 3.52 4.427-.267 2.187-2.24 3.787-4.427 3.52-8.64-1.013-18.08-.213-25.226 2.08-.427.107-.854.16-1.227.16ZM74.587 82.707c-1.707 0-3.253-1.067-3.787-2.774-.693-2.08.48-4.373 2.56-5.013 8.16-2.667 18.827-3.52 28.587-2.4 2.187.267 3.787 2.24 3.52 4.427-.267 2.186-2.24 3.733-4.427 3.52-8.64-1.014-18.08-.214-25.226 2.08-.427.106-.854.16-1.227.16Z"
        fill="currentColor"
      />
    </svg>
  )
}
