import * as React from 'react'
import { SVGProps } from 'react'
export const IconManabimemo = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M111.999 25.828c0-4.328-3.509-7.828-7.837-7.828H23.837A7.837 7.837 0 0 0 16 25.837v64.396a7.675 7.675 0 0 0 7.702 7.703h19.5v13.346A2.724 2.724 0 0 0 45.925 114c.718 0 1.398-.285 1.912-.798l3.002-3.001c7.881-7.881 18.362-12.222 29.511-12.222h23.812c4.328 0 7.837-3.497 7.837-7.825l-.001-64.326Zm-68.798 38.56c-3.53 0-6.4-2.87-6.4-6.399a6.406 6.406 0 0 1 6.4-6.398c3.53 0 6.4 2.87 6.4 6.398a6.407 6.407 0 0 1-6.4 6.399Zm20.8 0c-3.53 0-6.4-2.87-6.4-6.399a6.406 6.406 0 0 1 6.4-6.398c3.53 0 6.4 2.87 6.4 6.398a6.407 6.407 0 0 1-6.4 6.399Zm20.8 0c-3.53 0-6.4-2.87-6.4-6.399a6.406 6.406 0 0 1 6.4-6.398c3.53 0 6.4 2.87 6.4 6.398a6.407 6.407 0 0 1-6.4 6.399Z"
        fill="currentColor"
      />
    </svg>
  )
}
