import * as React from 'react'
import { SVGProps } from 'react'
export const Icon15SBack = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M62.37 39.042V18.627c0-2.452-2.607-4.026-4.777-2.884L37.179 26.488c-2.378 1.25-2.306 4.68.122 5.83l20.415 9.67c2.162 1.024 4.654-.554 4.654-2.946ZM49.085 83.303h5.767V55.746h-5.748l-7.123 4.946v5.194l6.76-4.698h.344v22.115ZM72.803 84.01c6.188 0 10.466-3.954 10.466-9.76v-.037c0-5.252-3.705-9.033-8.842-9.033-2.636 0-4.775 1.145-5.882 2.998H68.2l.688-7.849h12.737v-4.583H64.401l-1.28 16.309h4.946c.306-.592.764-1.108 1.28-1.528.917-.783 2.1-1.184 3.495-1.184 2.922 0 5.003 2.043 5.003 4.984v.038c0 2.96-2.062 5.042-5.022 5.042-2.598 0-4.488-1.623-4.89-3.647l-.038-.134h-5.232l.019.267c.325 4.603 4.22 8.117 10.121 8.117Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.38 33.603a35.852 35.852 0 1 1-25.704 21.81 4.074 4.074 0 0 0-7.574-3.006A44 44 0 1 0 54.649 25.64a4.074 4.074 0 1 0 1.731 7.962Z"
        fill="currentColor"
      />
    </svg>
  )
}
