import * as React from 'react'
import { SVGProps } from 'react'
export const IconSummaryMainpoint = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_summary_mainpoint_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.644 18.447a10.243 10.243 0 0 0-13.288 0l-6.512 5.55a10.243 10.243 0 0 1-5.828 2.414l-8.53.68a10.243 10.243 0 0 0-9.395 9.396l-.68 8.529a10.243 10.243 0 0 1-2.415 5.828l-5.55 6.512a10.243 10.243 0 0 0 0 13.288l5.55 6.512a10.243 10.243 0 0 1 2.415 5.829l.68 8.528a10.243 10.243 0 0 0 9.396 9.396l8.529.68a10.248 10.248 0 0 1 5.828 2.415l6.512 5.549a10.242 10.242 0 0 0 13.288 0l6.512-5.549a10.248 10.248 0 0 1 5.829-2.415l8.528-.68a10.243 10.243 0 0 0 9.396-9.396l.68-8.528a10.248 10.248 0 0 1 2.415-5.83l5.549-6.511a10.242 10.242 0 0 0 0-13.288l-5.549-6.512a10.248 10.248 0 0 1-2.415-5.828l-.68-8.53a10.243 10.243 0 0 0-9.396-9.395l-8.528-.68a10.243 10.243 0 0 1-5.83-2.415l-6.511-5.55Zm15.95 39.347a4.8 4.8 0 0 0-6.788-6.788L59.2 71.612 48.194 60.606a4.8 4.8 0 0 0-6.788 6.788l14.4 14.4a4.8 4.8 0 0 0 6.788 0l24-24Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="icon_summary_mainpoint_svg__a">
          <path fill="#fff" transform="translate(16 16)" d="M0 0h96v96H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
