import * as React from 'react'
import { SVGProps } from 'react'
export const IconPlayArrow = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.946 36.373v55.254c0 4.213 4.64 6.773 8.214 4.48L88.573 68.48c3.307-2.08 3.307-6.88 0-9.013L45.16 31.894c-3.574-2.294-8.214.266-8.214 4.48Z"
        fill="currentColor"
      />
    </svg>
  )
}
