interface Props {
  width?: number
  height?: number
}

export const MemoBlue: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width || 17}
      height={height || 16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 16H3.83287L13.6626 6.17025L10.3297 2.83738L0.5 12.6671V16ZM2.27753 13.4048L10.3297 5.35259L11.1474 6.17025L3.0952 14.2225H2.27753V13.4048Z"
        fill="#1A6DCC"
      />
      <path
        d="M14.1603 0.259964C13.8137 -0.0866546 13.2538 -0.0866546 12.9072 0.259964L11.2807 1.8864L14.6136 5.21928L16.24 3.59283C16.5867 3.24622 16.5867 2.68629 16.24 2.33968L14.1603 0.259964Z"
        fill="#1A6DCC"
      />
    </svg>
  )
}
