import * as React from 'react'
import { SVGProps } from 'react'
export const IconArrow = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.768 23.028a5.333 5.333 0 0 1 7.538-.26l40 37.333a5.334 5.334 0 0 1 0 7.798l-40 37.333a5.333 5.333 0 1 1-7.278-7.797L54.85 64 19.028 30.566a5.333 5.333 0 0 1-.26-7.538Z"
        fill="currentColor"
      />
    </svg>
  )
}
