import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryInnovation = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_innovation_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="m54.483 77.13.783 4.258c2.395-.015 4.804-.015 7.2-.015 3.503 0 7.02.015 10.524.015l.222-1.024c.251-1.172.517-2.344.71-3.532.295-1.765.606-3.531.916-5.297.813-4.644 3.814-9.438 4.287-14.141.799-7.88-.532-15.804-4.08-24.203-5.13-10.669-10.91-11.96-10.91-11.96-2.97 1.989-8.455 7.494-10.185 11.486-4.11 9.511-5.41 19.112-3.873 28.505.873 5.312 3.445 10.699 4.406 15.907Z"
          fill="#fff"
        />
        <path
          d="M74.587 84.415H53.595a2.22 2.22 0 0 1-2.173-1.825c-.148-.816-3.666-19.973-4.715-27.482-.533-3.828-2.247-23.549 16.098-36.696a2.214 2.214 0 0 1 2.572 0c18.36 13.147 16.63 32.868 16.098 36.696-1.05 7.509-4.567 26.666-4.715 27.482a2.22 2.22 0 0 1-2.173 1.825Zm-10.481-4.452h8.618c.916-5.074 3.504-19.38 4.346-25.463.458-3.28 1.907-19.87-12.994-31.488C49.176 34.63 50.624 51.22 51.082 54.5c.843 6.07 3.43 20.389 4.346 25.463h8.678Z"
          fill="#00253F"
        />
        <path
          d="M67.52 86.626c2.632-.356 3.135-.326 4.377 2.404 2.128 4.69 1.759 9.438-1.124 13.741-1.818 2.715-3.991 5.193-6.016 7.775-.296.371-.651.802-1.124.193-2.395-3.146-5.189-6.069-7.051-9.497-2.247-4.125-2.233-8.666.148-12.954 1.005-1.81 1.596-2.018 3.976-1.721-.133.445-.251.89-.384 1.32-1.346 4.304-.429 7.998 2.882 11.1.68.638 1.183.564 1.819-.015 3.281-3.057 4.198-6.737 2.912-10.996-.148-.46-.281-.905-.414-1.35Z"
          fill="#0081CC"
        />
        <path
          d="M48.126 82.65c-3.533.326-4.804 1.26-5.529 4.035-.207.817-.37 1.677-.31 2.508.148 1.914.458 3.829.71 5.728.044.356.162.712.28 1.172-1.788.49-3.503.965-5.233 1.38-.192.045-.62-.267-.695-.504-1.685-4.704-3.06-9.467-3.178-14.513-.089-3.457 1.7-5.965 4.228-7.924 1.98-1.543 4.243-2.745 6.386-4.08.163-.104.37-.134.74-.267.857 4.17 1.729 8.28 2.6 12.464ZM85.068 96.138c.296-1.885.71-3.606.798-5.357.074-1.499-.03-3.057-.37-4.511-.502-2.152-2.025-3.28-4.212-3.487l-1.168-.104c.872-4.17 1.744-8.28 2.646-12.598 1.3.712 2.469 1.276 3.563 1.988 2.38 1.529 4.863 3.042 6.297 5.594.665 1.173 1.212 2.538 1.345 3.859.355 3.694-.606 7.256-1.567 10.787-.414 1.5-.946 2.968-1.39 4.452-.192.623-.473.876-1.167.668-1.597-.49-3.193-.876-4.775-1.291Z"
          fill="#00253F"
        />
        <path
          d="M68.408 49.559c2.41-2.42 2.439-6.247.044-8.651-2.41-2.434-6.327-2.434-8.722-.015-2.365 2.404-2.35 6.262.06 8.666 2.453 2.404 6.223 2.404 8.618 0ZM67.506 58.818c-1.892-1.9-4.834-1.929-6.74-.074-1.834 1.78-1.864 4.986-.06 6.796 1.803 1.81 5.011 1.81 6.785 0 1.804-1.825 1.804-4.927.015-6.722Z"
          fill="#0081CC"
        />
      </g>
      <defs>
        <clipPath id="icon_category_innovation_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
