import * as React from 'react'
import { SVGProps } from 'react'
export const IconCategoryScience = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_category_science_svg__a)">
        <path
          d="M109.402 0H18.598C8.327 0 0 8.293 0 18.523v90.954C0 119.707 8.327 128 18.598 128h90.804c10.271 0 18.598-8.293 18.598-18.523V18.523C128 8.293 119.673 0 109.402 0Z"
          fill="#E4E6E9"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
        <path
          d="M74.533 37v24.793s11.818 20.862 15.332 26.38C93.379 93.69 93.207 100 84.352 100h-40.69c-8.854 0-9.06-6.31-5.512-11.828 3.549-5.517 15.332-26.379 15.332-26.379V37M48 28h31v9H48v-9Z"
          fill="#fff"
        />
        <path
          d="M92.423 89.81a17.235 17.235 0 0 0-1.582-3.056c-3.075-4.808-12.817-22.021-14.635-25.241v-22.6h3.12a2.266 2.266 0 0 0 2.26-2.27V28.54c0-1.261-1.02-2.27-2.26-2.27H47.97a2.266 2.266 0 0 0-2.262 2.27v8.102c0 1.261 1.02 2.27 2.262 2.27h3.12v22.615c-.74 1.306-2.765 4.882-5.116 9.007l-7.834 13.489a94.405 94.405 0 0 1-1.685 2.745c-1.02 1.602-1.715 3.175-2.1 4.704-.532 1.914-.783 4.377.459 6.529 1.463 2.552 4.464 3.843 8.943 3.843h39.81c5.484 0 7.85-2.24 8.87-4.11.487-.89.739-1.9.827-2.953.178-1.336.06-3.013-.842-4.971Zm-20.46-26.576c.163.282 1.553 2.745 3.445 6.084H51.874c1.907-3.339 3.296-5.802 3.444-6.084a2.28 2.28 0 0 0 .296-1.113V38.913h16.054v23.208c0 .386.103.772.295 1.113Zm-21.73-28.876v-3.562h26.816v3.562H50.233Z"
          fill="#00253F"
        />
        <path
          d="M56.796 91.31a4.34 4.34 0 0 0 4.331-4.348 4.34 4.34 0 0 0-4.33-4.348 4.34 4.34 0 0 0-4.332 4.348 4.34 4.34 0 0 0 4.331 4.347ZM69.583 82.732a4.34 4.34 0 0 0 4.332-4.347 4.34 4.34 0 0 0-4.332-4.348 4.34 4.34 0 0 0-4.331 4.348 4.34 4.34 0 0 0 4.331 4.347ZM63.182 64.703a4.34 4.34 0 0 0 4.332-4.348 4.34 4.34 0 0 0-4.332-4.347 4.34 4.34 0 0 0-4.33 4.347 4.34 4.34 0 0 0 4.33 4.348Z"
          fill="#0081CC"
        />
      </g>
      <defs>
        <clipPath id="icon_category_science_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
