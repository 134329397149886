import * as React from 'react'
import { SVGProps } from 'react'
export const IconPauseCircle = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64 10.666C34.56 10.666 10.667 34.56 10.667 64c0 29.44 23.893 53.333 53.333 53.333 29.44 0 53.334-23.893 53.334-53.333 0-29.44-23.894-53.334-53.334-53.334ZM53.334 85.334C50.4 85.333 48 82.933 48 80V48c0-2.934 2.4-5.334 5.334-5.334 2.933 0 5.333 2.4 5.333 5.334v32c0 2.933-2.4 5.333-5.333 5.333Zm21.333 0c-2.933 0-5.333-2.4-5.333-5.333V48c0-2.934 2.4-5.334 5.333-5.334C77.6 42.666 80 45.066 80 48v32c0 2.933-2.4 5.333-5.333 5.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
