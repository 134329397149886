interface Props {
  width?: number
  height?: number
  className?: string
}

export const ShareFacebook: React.FC<Props> = ({
  width = 30,
  height = 30,
  className = '',
}) => {
  return (
    <img
      src={`${process.env.IMG_SERVER}/share/fb100.png`}
      width={width}
      height={height}
      className={className}
      alt="facebook"
    />
  )
}
