import * as React from 'react'
import { SVGProps } from 'react'
export const IconMusicNote = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M63.996 26.663v45.6c-5.013-2.88-11.2-4-17.76-1.706-7.147 2.56-12.64 8.906-13.92 16.373-2.453 14.613 9.92 27.093 24.48 24.8 10.453-1.653 17.867-11.253 17.867-21.867V37.33h10.666c5.867 0 10.667-4.8 10.667-10.667 0-5.866-4.8-10.666-10.667-10.666H74.663c-5.867 0-10.667 4.8-10.667 10.666Z"
        fill="currentColor"
      />
    </svg>
  )
}
