import * as React from 'react'
import { SVGProps } from 'react'
export const IconTagFinished = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#icon_tag_finished_svg__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m0 0 128 128V0H0Zm79.26 31.4 3.132 3.13 1.086-1.085-3.73-3.73 1.739-1.737 3.729 3.729 1.357-1.358 2.136 2.136-1.358 1.358 3.983 3.982-1.738 1.738-3.982-3.982-1.086 1.086 3.439 3.44-1.738 1.737-8.707-8.707 1.738-1.738Zm3.077 11.693-6.281-6.281-1.774 1.774-1.9-1.901 3.457-3.458 10.173 10.174-3.457 3.457-1.992-1.991 1.774-1.774Zm-5.829-21.686 4.87 4.87-1.63 1.628-4.869-4.869 1.63-1.63Zm-3.33 1.52 6.353 6.354-1.701 1.702-6.354-6.354 1.702-1.702Zm-1.72 3.313 5.014 5.014-1.647 1.648-5.014-5.015 1.647-1.647Zm.923 9.105-5.014-5.014 1.611-1.61 5.014 5.013-1.61 1.611Zm-7.965 1.34 1.575 1.575 2.082-2.082-1.575-1.575-2.082 2.082Zm2.01-5.521 5.105 5.105-4.689 4.688c3.73 1.63 5.738.127 8.87-2.788l2.01 2.01c-3.929 3.638-6.861 5.593-11.604 3.638.272-.67.489-1.828.507-2.643l-.543.543-3.35-3.349-.723.724-1.756-1.755 6.173-6.173Zm9.485 17.16c-.525.526-.543.616-.271.888l.633.633c.29.29.652.072 2.697-1.9.073.723.58 1.882.978 2.57-2.86 2.498-4.019 2.715-5.322 1.412l-1.213-1.213c-1.575-1.575-1.34-2.534.489-4.363l4.978-4.978 1.991 1.992-4.96 4.96Zm30.294 4.191.669.38.435 2.933c-3.548.362-8.146.29-11.712-.525l-6.426 6.426c-1.25 1.249-2.136 1.52-3.35.96-1.23-.58-2.551-1.829-4.29-3.567.526-.778 1.087-2.136 1.358-3.059 1.195 1.34 2.68 2.824 3.114 3.222.398.399.615.435.923.127l8.2-8.2 1.213 1.213c1.883.325 4.019.434 5.901.362l-10.499-10.5 2.118-2.117 12.346 12.345Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="icon_tag_finished_svg__a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
