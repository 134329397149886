/* eslint tailwindcss/no-custom-classname: off */
import { SVGProps } from 'react'

export const IconArrowBlueGradation = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  const gradientId = `gradient-${props.id}`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="21"
      viewBox="0 0 14 21"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23952 3.83212C3.54569 3.49566 4.05984 3.47748 4.38788 3.79151L10.4816 9.62484C10.6463 9.78244 10.7397 10.0031 10.7397 10.2341C10.7397 10.465 10.6463 10.6857 10.4816 10.8433L4.38788 16.6766C4.05984 16.9906 3.54569 16.9724 3.23952 16.636C2.93334 16.2995 2.95107 15.7722 3.27912 15.4582L8.73646 10.2341L3.27912 5.00993C2.95107 4.6959 2.93334 4.16858 3.23952 3.83212Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="12.6993"
          y1="-1.1677"
          x2="-2.31596"
          y2="9.14599"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.20622" stopColor="#408BED" />
          <stop offset="1" stopColor="#044DAB" />
        </linearGradient>
      </defs>
    </svg>
  )
}
