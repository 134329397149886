export const SearchDark: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7643 8.64684C14.7643 12.0254 12.0254 14.7643 8.64684 14.7643C5.26824 14.7643 2.52934 12.0254 2.52934 8.64684C2.52934 5.26824 5.26824 2.52934 8.64684 2.52934C12.0254 2.52934 14.7643 5.26824 14.7643 8.64684ZM13.5867 15.7447C12.1863 16.7211 10.4834 17.2937 8.64684 17.2937C3.87132 17.2937 0 13.4224 0 8.64684C0 3.87132 3.87132 0 8.64684 0C13.4224 0 17.2937 3.87132 17.2937 8.64684C17.2937 10.4835 16.7211 12.1864 15.7446 13.5867L19.0408 16.8829C19.6367 17.4788 19.6367 18.445 19.0408 19.0409C18.4449 19.6368 17.4788 19.6368 16.8829 19.0409L13.5867 15.7447Z"
        fill="#666666"
      />
    </svg>
  )
}
