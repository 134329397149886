import * as React from 'react'
import { SVGProps } from 'react'
export const IconShare = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m47.308 23.634 14.88-14.88a2.624 2.624 0 0 1 3.733.053l14.88 14.88c1.707 1.653.534 4.533-1.866 4.533h-9.547v52.667a2 2 0 0 1-2 2h-6.667a2 2 0 0 1-2-2V28.22h-9.546c-2.4 0-3.573-2.88-1.867-4.586ZM45 41H30a8 8 0 0 0-8 8v63a8 8 0 0 0 8 8h68a8 8 0 0 0 8-8V49a8 8 0 0 0-8-8H83a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h12v57H33V52h12a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2Z"
        fill="currentColor"
      />
    </svg>
  )
}
