import * as React from 'react'
import { SVGProps } from 'react'
export const IconMode = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.993 93.126v16.214a2.641 2.641 0 0 0 2.667 2.667h16.213c.694 0 1.387-.267 1.867-.8L94.98 53.02l-20-20-58.187 58.187a2.618 2.618 0 0 0-.8 1.92Zm94.453-55.573a5.311 5.311 0 0 0 0-7.52l-12.48-12.48a5.311 5.311 0 0 0-7.52 0l-9.76 9.76 20 20 9.76-9.76Z"
        fill="currentColor"
      />
    </svg>
  )
}
