import * as React from 'react'
import { SVGProps } from 'react'
export const IconReadingProgram = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 16C19.0545 16 16.6667 18.3878 16.6667 21.3333L16.6667 106.333C16.6667 109.279 19.0545 111.667 22 111.667L38.6667 111.667C41.6122 111.667 44 109.279 44 106.333L44 21.3333C44 18.3878 41.6122 16 38.6667 16L22 16ZM34.6667 26.6667C35.2189 26.6667 35.6667 27.1144 35.6667 27.6667L35.6667 36.3333C35.6667 36.8856 35.2189 37.3333 34.6667 37.3333L26 37.3333C25.4477 37.3333 25 36.8856 25 36.3333L25 27.6667C25 27.1144 25.4477 26.6667 26 26.6667L34.6667 26.6667ZM110 21.3333C110 18.3878 107.612 16 104.667 16L88 16C85.0545 16 82.6667 18.3878 82.6667 21.3333L82.6667 106.333C82.6667 109.279 85.0545 111.667 88 111.667L104.667 111.667C107.612 111.667 110 109.279 110 106.333L110 21.3333ZM92 37.3333C91.4477 37.3333 91 36.8856 91 36.3333L91 27.6667C91 27.1144 91.4477 26.6667 92 26.6667L100.667 26.6667C101.219 26.6667 101.667 27.1144 101.667 27.6667L101.667 36.3333C101.667 36.8856 101.219 37.3333 100.667 37.3333L92 37.3333ZM55 16C52.0545 16 49.6667 18.3878 49.6667 21.3333L49.6667 106.333C49.6667 109.279 52.0545 111.667 55 111.667L71.6667 111.667C74.6122 111.667 77 109.279 77 106.333L77 21.3333C77 18.3878 74.6122 16 71.6667 16L55 16ZM67.6667 26.6667C68.2189 26.6667 68.6667 27.1144 68.6667 27.6667L68.6667 36.3333C68.6667 36.8856 68.2189 37.3333 67.6667 37.3333L59 37.3333C58.4477 37.3333 58 36.8856 58 36.3333L58 27.6667C58 27.1144 58.4477 26.6667 59 26.6667L67.6667 26.6667Z"
        fill="currentColor"
      />
    </svg>
  )
}
