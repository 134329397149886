import { SVGProps } from 'react'
interface Props extends SVGProps<SVGSVGElement> {
  className?: string
}

export const MicBlue = (props: Props): JSX.Element => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M63.994 77.333c8.854 0 16-7.146 16-16v-32c0-8.853-7.146-16-16-16-8.853 0-16 7.147-16 16v32c0 8.854 7.147 16 16 16Zm31.52-16c-2.613 0-4.8 1.92-5.226 4.534C88.1 78.4 77.168 88 63.994 88c-13.173 0-24.106-9.6-26.293-22.133-.427-2.614-2.613-4.534-5.227-4.534-3.253 0-5.813 2.88-5.333 6.08 2.613 16 15.413 28.534 31.52 30.827v11.093c0 2.934 2.4 5.334 5.333 5.334 2.934 0 5.334-2.4 5.334-5.334V98.24c16.106-2.293 28.906-14.827 31.52-30.827.533-3.2-2.08-6.08-5.334-6.08Z"
        fill="#1A6DCC"
      />
    </svg>
  )
}
