import * as React from 'react'
import { SVGProps } from 'react'
export const IconKeyboardReturn = (
  props: SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M102.887 42.64v16h-70.24l15.36-15.36a5.311 5.311 0 0 0 0-7.52 5.311 5.311 0 0 0-7.52 0l-24.48 24.48a5.311 5.311 0 0 0 0 7.52l24.48 24.48a5.311 5.311 0 0 0 7.52 0 5.311 5.311 0 0 0 0-7.52l-15.36-15.413h75.573c2.933 0 5.333-2.4 5.333-5.334V42.64c0-2.933-2.4-5.333-5.333-5.333-2.933 0-5.333 2.4-5.333 5.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
