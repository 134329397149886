import * as React from 'react'
import { SVGProps } from 'react'
export const IconBook = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M96 10.666H32c-5.867 0-10.667 4.8-10.667 10.667v85.334c0 5.866 4.8 10.666 10.667 10.666h64c5.866 0 10.666-4.8 10.666-10.666V21.333c0-5.866-4.8-10.666-10.666-10.666ZM32 21.334h26.666V64l-13.333-8L32 64V21.333Z"
        fill="currentColor"
      />
    </svg>
  )
}
