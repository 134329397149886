import * as React from 'react'
import { SVGProps } from 'react'
export const IconGroup = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.333 58.666c8.853 0 15.947-7.146 15.947-16 0-8.853-7.094-16-15.947-16-8.853 0-16 7.147-16 16 0 8.854 7.147 16 16 16Zm-42.667 0c8.854 0 15.947-7.146 15.947-16 0-8.853-7.093-16-15.947-16-8.853 0-16 7.147-16 16 0 8.854 7.147 16 16 16Zm0 10.667C30.24 69.333 5.333 75.573 5.333 88v8c0 2.933 2.4 5.333 5.333 5.333h64C77.6 101.333 80 98.933 80 96v-8c0-12.427-24.907-18.667-37.334-18.667Zm42.667 0c-1.547 0-3.307.107-5.173.267.106.053.16.16.213.213C86.453 74.24 90.666 80.16 90.666 88v8c0 1.867-.373 3.68-.96 5.333h27.627c2.933 0 5.333-2.4 5.333-5.333v-8c0-12.427-24.906-18.667-37.333-18.667Z"
        fill="currentColor"
      />
      <text x="-35" y="150" fill="#666666" fontSize="50px" width="37px">
        ビジネス
      </text>
    </svg>
  )
}
